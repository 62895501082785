import React from "react";
import logo from "../assets/img/logo2.png";
import PlaceholderLoading from "react-placeholder-loading";
import { signInSchool } from "../controller";

function LogIn() {
  const [schoolNo, setSchoolNo] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [err, setErr] = React.useState(null);
  const [isSubmitting, setSubmitting] = React.useState(false);

  React.useEffect(() => {
    if (localStorage.getItem("school")) {
      const school = JSON.parse(localStorage.getItem("school"));
      if (school._id) {
        return (window.location.href = "/admin");
      }
    }
    if (localStorage.getItem("admin")) {
      const admin = JSON.parse(localStorage.getItem("admin"));
      if (admin._id) {
        return (window.location.href = "/main");
      }
    }
  }, []);

  const handleSignin = async (event) => {
    event.preventDefault();
    try {
      if (!schoolNo || !password) {
        return setErr("Enter all fields");
      }
      setSubmitting(true);
      const body = {
        regno: schoolNo,
        password: password,
      };

      const feedback = await signInSchool(body);
      console.log(feedback);
      setSubmitting(false);
      if (feedback.status === 200) {
        localStorage.setItem("school", JSON.stringify(feedback.data));
        return (window.location.href = "/admin");
      }
    } catch (error) {
      setSubmitting(false);
      console.log(error);
      return setErr("School number or password is incorrect");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "10%",
      }}
    >
      <img src={logo} style={{ width: "10%", marginBottom: 20 }} alt="logo" />
      {err ? <div style={{ color: "red" }}>{err}</div> : null}
      <div
        style={{
          width: "50%",
          border: "2px solid #eee",
          padding: 50,
          borderRadius: 5,
        }}
      >
        <form onSubmit={handleSignin}>
          <h3 style={{ fontWeight: "bold" }}>School Management System</h3>
          <div className=" mb-3">
            <label className="form-label">School Number</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => {
                setErr(null);
                setSchoolNo(e.target.value);
              }}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Password</label>
            <input
              type="password"
              className="form-control"
              onChange={(e) => {
                setErr(null);
                setPassword(e.target.value);
              }}
            />
          </div>

          {isSubmitting ? (
            <PlaceholderLoading shape="rect" width='100%' height={35} />
          ) : (
            <button type="submit" className="btn btn-primary" style={{width:'100%'}}>
              Log In
            </button>
          )}
        </form>
      </div>
      <div style={{ fontSize: 12, opacity: 0.5, marginTop: 20 }}>
        &copy; Bara App {new Date().getFullYear()}{" "}
        <span
          onClick={() => (window.location.href = "/login")}
          style={{ color: "blue", cursor: "pointer" }}
        >
          Admin
        </span>
      </div>
    </div>
  );
}

export default LogIn;
