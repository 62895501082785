import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import PanelHeader from "components/PanelHeader/PanelHeader.js";
import {
  addSubject,
  getSchool,
  getSubjects,
  updateSubject,
  deleteSubject,
} from "../controller";

function RegularTables() {
  const [modal, setModal] = React.useState(false);
  const [modal1, setModal1] = React.useState(false);
  const [subjectName, setSubjectName] = React.useState(null);
  const [newSubject, setNewSubject] = React.useState(null);
  const [isSubmiting, setSubmiting] = React.useState(false);
  const [isDelete, setDelete] = React.useState(false);
  const [subjects, setSubjects] = React.useState([]);
  const [selectedId, setSelectedId] = React.useState(null);

  const [search, setSearch] = React.useState(null);

  const toggle = () => {
    setModal(!modal);
  };

  const toggle1 = () => {
    setModal1(!modal1);
  };

  React.useEffect(() => {
    if (!localStorage.getItem("school")) {
      return (window.location.href = "/");
    }
    const profile = JSON.parse(localStorage.getItem("school"));
    getSubjects(profile._id)
      .then((re) => {
        setSubjects(re.data);
        setSearch(re.data);
      })
      .catch((err) => console.log(err));
    getSchool(profile._id).then().catch();
  }, []);

  const updateSearch = (text) => {
    if (text === "") {
      return setSearch(subjects);
    }

    const regexp = new RegExp(text, "i");
    const filtered = subjects.filter((x) => regexp.test(x.name));
    return setSearch(filtered);
  };

  const updateSub = async () => {
    try {
      if (!newSubject) {
        return;
      }

      setSubmiting(true);
      const body = {
        name: newSubject,
      };
      const feed = await updateSubject(selectedId, body);
      setSubmiting(false);
      if (feed.status === 202) {
        return window.location.reload();
      } else {
        return alert("Failed to add subject, Please try again");
      }
    } catch (error) {
      setSubmiting(false);
      alert("Failed to update subject, Please try again");
      console.log(error);
    }
  };

  const addSub = async () => {
    const shule = JSON.parse(localStorage.getItem("school"));
    try {
      if (!subjectName) {
        return;
      }
      setSubmiting(true);
      const body = {
        name: subjectName,
        school: shule._id,
      };
      const feed = await addSubject(body);
      setSubmiting(false);
      if (feed.status === 201) {
        return window.location.reload();
      } else {
        return alert("Failed to add subject, Please try again");
      }
    } catch (error) {
      setSubmiting(false);
      alert("Failed to add subject, Please try again");
      console.log(error);
    }
  };

  const deleteSub = async (id) => {
    try {
      setDelete(true);
      const feed = await deleteSubject(id);
      setDelete(false);
      if (feed.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      setDelete(false);
      console.log(error);
    }
  };

  const head = ["Subject Name", "Created At", "Updated At", "Action"];

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <CardTitle tag="h4">Subject List</CardTitle>
                <Form inline className="ml-auto">
                  <FormGroup className="no-border">
                    <Input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => updateSearch(e.target.value)}
                    />
                  </FormGroup>
                </Form>
                <button
                  style={{
                    fontWeight: "bold",
                    marginRight: 10,
                    marginLeft: 40,
                  }}
                  onClick={toggle}
                  type="button"
                  className=" btn btn-info btn-sm "
                >
                  <i style={{ fontSize: 20 }} className="fas fa-plus-circle" />
                </button>
              </CardHeader>
              <CardBody>
                <Table responsive bordered>
                  <thead>
                    <tr>
                      {head.map((prop, key) => {
                        return (
                          <th
                            key={key}
                            className="text-left"
                            style={{ fontWeight: "bold", fontSize: 14 }}
                          >
                            {prop}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  {search ? (
                    <>
                      {search.map((sub) => (
                        <tbody key={sub._id}>
                          <td>{sub.name}</td>
                          <td>{new Date(sub.createdAt).toLocaleString()}</td>
                          <td>{new Date(sub.updatedAt).toLocaleString()}</td>

                          <td>
                            <button
                              style={{ fontWeight: "bold", marginRight: 10 }}
                              type="button"
                              className="btn btn-warning btn-sm "
                              onClick={() => {
                                setSelectedId(sub._id);
                                setModal1(true);
                              }}
                            >
                              <i className="far fa-edit"></i>
                            </button>
                            <button
                              style={{ fontWeight: "bold" }}
                              type="button"
                              className="btn btn-danger btn-sm "
                              onClick={() => deleteSub(sub._id)}
                            >
                              <i
                                className={
                                  isDelete ? "far fa-flag" : "far fa-trash-alt"
                                }
                              ></i>
                            </button>
                          </td>
                        </tbody>
                      ))}
                    </>
                  ) : null}
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal isOpen={modal} toggle={toggle} backdrop={false}>
          <ModalHeader toggle={toggle}>Add New Subject</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <label>New Subject</label>
                  <Input
                    type="text"
                    onChange={(e) => setSubjectName(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              onClick={addSub}
              disabled={isSubmiting}
              style={{ fontWeight: "bold" }}
            >
              Add Subject
            </Button>{" "}
            <Button
              color="secondary"
              onClick={toggle}
              style={{ fontWeight: "bold" }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modal1} toggle={toggle1} backdrop={false}>
          <ModalHeader toggle={toggle1}>Update Subject</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <label>Subject Name</label>
                  <Input
                    type="text"
                    onChange={(e) => setNewSubject(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              onClick={updateSub}
              disabled={isSubmiting}
              style={{ fontWeight: "bold" }}
            >
              Update Subject
            </Button>{" "}
            <Button
              color="secondary"
              onClick={toggle1}
              style={{ fontWeight: "bold" }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default RegularTables;
