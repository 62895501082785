import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import {
  addExamType,
  getSchool,
  getExamType,
  getClasses,
  updateExamType,
  deleteExamType,
} from "../controller";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

function RegularTables(props) {
  const [modal, setModal] = React.useState(false);
  const [modal1, setModal1] = React.useState(false);
  const [subjectName, setSubjectName] = React.useState(null);
  const [newSubject, setNewSubject] = React.useState(null);
  const [isSubmiting, setSubmiting] = React.useState(false);
  const [isDelete, setDelete] = React.useState(false);
  const [subjects, setSubjects] = React.useState([]);
  const [selectedId, setSelectedId] = React.useState(null);
  const [newType, setNewType] = React.useState(null);
  const [month, setMonth] = React.useState(null);
  const [year, setYear] = React.useState(null);
  const [classSelect, setClass] = React.useState(null);
  const [madarasa, setMadarasa] = React.useState([]);

  const [search, setSearch] = React.useState(null);

  const toggle = () => {
    setModal(!modal);
  };

  const toggle1 = () => {
    setModal1(!modal1);
  };

  React.useEffect(() => {
    if (!localStorage.getItem("school")) {
      return (window.location.href = "/");
    }
    const profile = JSON.parse(localStorage.getItem("school"));
    getExamType(profile._id)
      .then((re) => {
        setSubjects(re.data);
        setSearch(re.data);
      })
      .catch((err) => console.log(err));
    getSchool(profile._id).then().catch();
    getClasses(profile._id)
      .then((re) => {
        setMadarasa(re.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const updateSearch = (text) => {
    if (text === "") {
      return setSearch(subjects);
    }

    const regexp = new RegExp(text, "i");
    const filtered = subjects.filter((x) => regexp.test(x.name));
    return setSearch(filtered);
  };

  const updateSub = async () => {
    try {
      if (!newSubject) {
        return;
      }

      setSubmiting(true);
      const body = {
        name: newSubject,
      };
      const feed = await updateExamType(selectedId, body);
      setSubmiting(false);
      if (feed.status === 202) {
        return window.location.reload();
      } else {
        return alert("Failed to add exam type, Please try again");
      }
    } catch (error) {
      setSubmiting(false);
      alert("Failed to update exam type, Please try again");
      console.log(error);
    }
  };

  const addSub = async () => {
    const shule = JSON.parse(localStorage.getItem("school"));
    try {
      if (!newType || !classSelect || !month || !year) {
        return;
      }
      setSubmiting(true);
      const body = {
        name: newType.label,
        school: shule._id,
        year: year.value,
        month: month.value,
        class: classSelect.value,
      };

      const feed = await addExamType(body);
      setSubmiting(false);
      if (feed.status === 201) {
        return window.location.reload();
      } else {
        return alert("Failed to add exam type, Please try again");
      }
    } catch (error) {
      setSubmiting(false);
      alert("Failed to add exam type, Please try again");
      console.log(error);
    }
  };

  const deleteSub = async (id) => {
    try {
      setDelete(true);
      const feed = await deleteExamType(id);
      setDelete(false);
      if (feed.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      setDelete(false);
      console.log(error);
    }
  };

  const goToSeeResult = (name) => {
    return props.history.push({
      pathname: "/admin/seeresult",
      state: {
        name: name,
      },
    });
    //return window.location.href = `/admin/seeresult/${name}`
  };

  const head = ["Examination type", "Class", "Created At", "See", "Action"];

  const typesOptions = [
    { value: 1, label: "Monthly" },
    { value: 2, label: "Terminal" },
    { value: 3, label: "Annual" },
  ];

  const getAllMonths = () => {
    let arr = [];
    for (let index = 1; index < 13; index++) {
      arr.push({ value: index, label: `${index}` });
    }
    return arr;
  };

  const getAllYears = () => {
    let arr = [];
    for (
      let index = new Date().getFullYear();
      index <= new Date().getFullYear() + 3;
      index++
    ) {
      arr.push({ value: index, label: `${index}` });
    }
    return arr;
  };

  const twikClass = (data) => {
    const list = [];
    data.forEach((sub) => list.push({ value: sub._id, label: sub.name }));
    return list;
  };

  const classOptions = twikClass(madarasa);
  const monthOptions = getAllMonths();
  const yearOptions = getAllYears();

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <CardTitle tag="h4">Exam Type List</CardTitle>
                <Form inline className="ml-auto">
                  <FormGroup className="no-border">
                    <Input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => updateSearch(e.target.value)}
                    />
                  </FormGroup>
                </Form>
                <button
                  style={{
                    fontWeight: "bold",
                    marginRight: 10,
                    marginLeft: 40,
                  }}
                  onClick={toggle}
                  type="button"
                  className=" btn btn-info btn-sm "
                >
                  <i style={{ fontSize: 20 }} className="fas fa-plus-circle" />
                </button>
              </CardHeader>
              <CardBody>
                <Table responsive bordered>
                  <thead>
                    <tr>
                      {head.map((prop, key) => {
                        return (
                          <th
                            key={key}
                            className="text-left"
                            style={{ fontWeight: "bold", fontSize: 14 }}
                          >
                            {prop}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  {search ? (
                    <>
                      {search.map((sub) => (
                        <tbody key={sub._id}>
                          <td>
                            {sub.name}{" "}
                            {sub.month ? sub.month + "/" + sub.year : ""}
                          </td>
                          <td>{sub.class ? sub.class.name : ""}</td>
                          <td>{new Date(sub.createdAt).toLocaleString()}</td>
                          <td>
                            <Link
                              to={{
                                pathname: "../admin/seeresult",
                                state: {
                                  name: sub.month
                                    ? sub.name +
                                      " - " +
                                      sub.month +
                                      " - " +
                                      sub.year +
                                      ` (${sub.class.name})`
                                    : sub.name,
                                },
                              }}
                            >
                              <button
                                style={{ fontWeight: "bold" }}
                                type="button"
                                className="btn btn-success btn-sm"
                              >
                                View
                              </button>
                            </Link>
                          </td>

                          {/* <td>
                            <button
                              style={{ fontWeight: "bold" }}
                              type="button"
                              className="btn btn-info btn-sm"
                            >
                              View
                            </button>
                          </td> */}

                          <td>
                            <button
                              style={{ fontWeight: "bold", marginRight: 10 }}
                              type="button"
                              className="btn btn-warning btn-sm "
                              onClick={() => {
                                setSelectedId(sub._id);
                                setModal1(true);
                              }}
                            >
                              <i className="far fa-edit"></i>
                            </button>
                            <button
                              style={{ fontWeight: "bold" }}
                              type="button"
                              className="btn btn-danger btn-sm "
                              onClick={() => deleteSub(sub._id)}
                            >
                              <i
                                className={
                                  isDelete ? "far fa-flag" : "far fa-trash-alt"
                                }
                              ></i>
                            </button>
                          </td>
                        </tbody>
                      ))}
                    </>
                  ) : null}
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal isOpen={modal} toggle={toggle} backdrop={false}>
          <ModalHeader toggle={toggle}>Add New Exam Type</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <label>Exam type</label>
                  <Select
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    onChange={(e) => setNewType(e)}
                    options={typesOptions}
                  />
                </FormGroup>

                <FormGroup>
                  <label>Class</label>
                  <Select
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    onChange={(e) => setClass(e)}
                    options={classOptions}
                  />
                </FormGroup>
                <FormGroup>
                  <label>Month</label>
                  <Select
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    onChange={(e) => setMonth(e)}
                    options={monthOptions}
                  />
                </FormGroup>
                <FormGroup>
                  <label>Year</label>
                  <Select
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    onChange={(e) => setYear(e)}
                    options={yearOptions}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              onClick={addSub}
              disabled={isSubmiting}
              style={{ fontWeight: "bold" }}
            >
              Add Exam Type
            </Button>{" "}
            <Button
              color="secondary"
              onClick={toggle}
              style={{ fontWeight: "bold" }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modal1} toggle={toggle1} backdrop={false}>
          <ModalHeader toggle={toggle1}>Update Exam Type</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <label>Exam Type</label>
                  <Input
                    type="text"
                    onChange={(e) => setNewSubject(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              onClick={updateSub}
              disabled={isSubmiting}
              style={{ fontWeight: "bold" }}
            >
              Update Exam Type
            </Button>{" "}
            <Button
              color="secondary"
              onClick={toggle1}
              style={{ fontWeight: "bold" }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default RegularTables;
