const tasks = [
  {
    checked: true,
    text: 'Sign contract for "What are conference organizers afraid of?"',
  },
  {
    checked: false,
    text: "Lines From Great Russian Literature? Or E-mails From My Boss?",
  },
  {
    checked: true,
    text: "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
  },
];

const thead = [
  "Exam Name",
  "Class Name",
  "Subject",
  "Is Live",
  "Exam Date",
  "Action",
];
const tbody = [
  {
    className: "table-success",
    data: [
      "Terminal",
      "Form II",
      "English",
      "31/09/2021",
      "yes",
      "10/09/2021",
      "Enable",
      "edit",
    ],
  },

  {
    className: "table-success",
    data: [
      "Terminal",
      "Form I",
      "Swahili",
      "31/09/2021",
      "yes",
      "12/09/2021",
      "Enable",
      "edit",
    ],
  },
];

// tasks list for Tasks card in Dashboard view
// data for <thead> of table in TableList view
// data for <tbody> of table in TableList view
export { tasks, thead, tbody };
