import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { getSchool, updateSchoolInfo } from "../controller";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

function User() {
  const [profile, setProfile] = React.useState(null);
  const [isSubmiting, setSubmiting] = React.useState(false);

  //fields
  const [email, setEmail] = React.useState(null);
  const [phone, setphone] = React.useState(null);
  const [address, setaddress] = React.useState(null);
  const [city, setcity] = React.useState(null);
  const [wilaya, setwilaya] = React.useState(null);

  const [modal, setModal] = React.useState(false);

  const toggle = () => setModal(!modal);

  React.useEffect(() => {
    if (!localStorage.getItem("school")) {
      return (window.location.href = "/");
    }
    setProfile(JSON.parse(localStorage.getItem("school")));
  }, []);

  const handleUpdateDetails = async () => {
    try {
      if (!email && !phone && !address && !city && !wilaya) {
        return;
      }
      setSubmiting(true);
      let ph = profile.phone;
      if (phone) {
        ph = phone.replace(/\D/g, "");
      }
      const body = {
        address: address ? address : profile.address,
        district: wilaya ? wilaya : profile.district,
        email: email ? email : profile.email,
        phone: ph,
        region: city ? city : profile.region,
      };
      const feed = await updateSchoolInfo(profile._id, body);
      setSubmiting(false);
      if (feed.status === 202) {
        await getSchool(profile._id);
        return window.location.reload();
      }
    } catch (error) {
      setSubmiting(false);
      console.log(error);
    }
  };

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        {profile ? (
          <Row>
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">My Profile</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="5">
                        <FormGroup>
                          <label>School Name</label>
                          <Input
                            defaultValue={profile.name}
                            disabled
                            placeholder={profile.name}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="px-1" md="3">
                        <FormGroup>
                          <label>School Number</label>
                          <Input
                            defaultValue={profile.regno}
                            disabled
                            placeholder={profile.regno}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="4">
                        <FormGroup>
                          <label htmlFor="exampleInputEmail1">
                            Email address
                          </label>
                          <Input
                            defaultValue={profile.email}
                            placeholder={profile.email}
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Phone Number</label>
                          <Input
                            defaultValue={profile.phone}
                            placeholder={`+${profile.phone}`}
                            type="text"
                            onChange={(e) => setphone(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="6">
                        <FormGroup>
                          <label>Level</label>
                          <Input
                            defaultValue={profile.level}
                            placeholder={profile.level}
                            disabled
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Address</label>
                          <Input
                            defaultValue={profile.address}
                            placeholder={profile.address}
                            onChange={(e) => setaddress(e.target.value)}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="4">
                        <FormGroup>
                          <label>City</label>
                          <Input
                            defaultValue={profile.region}
                            placeholder={profile.region}
                            onChange={(e) => setcity(e.target.value)}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="px-1" md="4">
                        <FormGroup>
                          <label>District</label>
                          <Input
                            defaultValue={profile.district}
                            placeholder={profile.district}
                            onChange={(e) => setwilaya(e.target.value)}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="4">
                        <FormGroup>
                          <label>Postal Code</label>
                          <Input placeholder="00255" disabled type="number" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md="12"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <button
                          style={{ fontWeight: "bold" }}
                          type="button"
                          class="btn btn-link "
                          onClick={toggle}
                        >
                          Change Password
                        </button>
                        <button
                          style={{ fontWeight: "bold" }}
                          type="button"
                          class="btn btn-info "
                          disabled={isSubmiting}
                          onClick={handleUpdateDetails}
                        >
                          Update Profile
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-user">
                <div className="image">
                  <img alt="..." src={require("assets/img/bg5.jpg").default} />
                </div>
                <CardBody>
                  <div className="author">
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="avatar border-black"
                        src={require("assets/img/campus.png").default}
                      />
                      <h5 className="title">{profile.name}</h5>
                    </a>
                    <p className="description">
                      {profile.region},{profile.district}
                    </p>
                  </div>
                  <p className="description text-center">{profile.address}</p>
                </CardBody>
                <hr />
                <div className="button-container">
                  <Button
                    className="btn-neutral btn-icon btn-round"
                    color="default"
                    href="#"
                    onClick={(e) => e.preventDefault()}
                    size="lg"
                  >
                    <i className="fab fa-facebook-f" />
                  </Button>
                  <Button
                    className="btn-neutral btn-icon btn-round"
                    color="default"
                    href="#"
                    onClick={(e) => e.preventDefault()}
                    size="lg"
                  >
                    <i className="fab fa-twitter" />
                  </Button>
                  <Button
                    className="btn-neutral btn-icon btn-round"
                    color="default"
                    href="#"
                    onClick={(e) => e.preventDefault()}
                    size="lg"
                  >
                    <i className="fab fa-google-plus-g" />
                  </Button>
                </div>
                <div style={{ textAlign: "center", padding: "0 20px" }}>
                  <Button
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="btn btn-danger"
                    color="default"
                    onClick={(e) => {
                      localStorage.removeItem("school");
                      localStorage.removeItem("admin");
                      return (window.location.href = "/");
                    }}
                    size="sm"
                  >
                    <i class="fas fa-sign-out-alt"></i>
                    <div style={{ fontWeight: "bold", marginLeft: 10 }}>
                      Sign Out
                    </div>
                  </Button>
                </div>
              </Card>
            </Col>
          </Row>
        ) : null}

        <Modal isOpen={modal} toggle={toggle} backdrop={false}>
          <ModalHeader toggle={toggle}>Change Password</ModalHeader>
          <ModalBody>
            <p className="text-primary">
              You can not change password for now. Try after 10 days.
            </p>
            <Row>
              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <label>New Password</label>
                  <Input disabled type="password" />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              onClick={toggle}
              style={{ fontWeight: "bold" }}
            >
              Update Password
            </Button>{" "}
            <Button
              color="secondary"
              onClick={toggle}
              style={{ fontWeight: "bold" }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default User;
