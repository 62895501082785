import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import PanelHeader from "components/PanelHeader/PanelHeader.js";
import {
  getSchool,
  getClasses,
  getSubjects,
  addNewClass,
  deleteClass,
  updateClass,
  getAllStudents,
} from "../controller";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

function ClassView() {
  const [madarasa, setMadarasa] = React.useState([]);
  const [modal, setModal] = React.useState(false);
  const [modal1, setModal1] = React.useState(false);
  const [classname, setclassname] = React.useState(null);
  const [isSubmiting, setSubmiting] = React.useState(false);
  const [isDelete, setDelete] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [subjectSelected, setSubjectSelected] = React.useState([]);
  const [subjects, setSubjects] = React.useState([]);
  const [students, setStudents] = React.useState([]);
  const [classId, setClassId] = React.useState(null);
  const [defaultName, setDefaultName] = React.useState(null);
  const [newName, setNewName] = React.useState(null);
  const [defaultSubs, setDefaultSubs] = React.useState(null);
  const [newsubjectSelected, setnewSubjectSelected] = React.useState([]);

  const [search, setSearch] = React.useState(null);

  const twikSubjects = (data) => {
    const list = [];
    data.forEach((sub) => list.push({ value: sub._id, label: sub.name }));
    return list;
  };

  const toggle = () => {
    setModal(!modal);
  };
  const toggle1 = (darasa) => {
    setClassId(darasa._id);
    setDefaultName(darasa.name);
    setDefaultSubs(twikSubjects(darasa.subjects));
    return setModal1(!modal1);
  };

  const subjectOptions = twikSubjects(subjects);

  React.useEffect(() => {
    if (!localStorage.getItem("school")) {
      return (window.location.href = "/");
    }
    const profile = JSON.parse(localStorage.getItem("school"));
    getClasses(profile._id)
      .then((re) => {
        setMadarasa(re.data);
        setSearch(re.data);
      })
      .catch((err) => console.log(err));
    getSchool(profile._id).then().catch();
    getSubjects(profile._id)
      .then((re) => setSubjects(re.data))
      .catch((err) => console.log(err));
    getAllStudents().then((res) => setStudents(res.data));
  }, []);

  const updateSearch = (text) => {
    if (text === "") {
      return setSearch(madarasa);
    }

    const regexp = new RegExp(text, "i");
    const filtered = madarasa.filter((x) => regexp.test(x.name));
    return setSearch(filtered);
  };

  const addClass = async () => {
    try {
      const profile = JSON.parse(localStorage.getItem("school"));
      if (!classname || subjectSelected.length <= 0) {
        return alert("Fill class name with atlest one class subject.");
      }
      setSubmiting(true);

      const newSubs = [];
      subjectSelected.forEach((sub) => newSubs.push(sub.value));

      if (newSubs.length > 0) {
        const body = {
          name: classname,
          school: profile._id,
          subjects: newSubs,
        };
        const feed = await addNewClass(body);
        setSubmiting(false);
        if (feed.status === 201) {
          return window.location.reload();
        } else {
          return alert("Failed to add class, Please try again");
        }
      }
    } catch (error) {
      setSubmiting(false);
      console.log(error);
    }
  };

  const updateToNewClass = async () => {
    try {
      setSubmiting(true);

      const newSubs = [];
      newsubjectSelected.length > 0
        ? newsubjectSelected.forEach((sub) => newSubs.push(sub.value))
        : defaultSubs.forEach((sub) => newSubs.push(sub.value));

      if (newSubs.length > 0) {
        const body = {
          name: newName ? newName : defaultName,
          subjects: newSubs,
        };
        const feed = await updateClass(classId, body);
        setSubmiting(false);
        if (feed.status === 202) {
          return window.location.reload();
        } else {
          return alert("Failed to add class, Please try again");
        }
      }
    } catch (error) {
      setSubmiting(false);
      console.log(error);
    }
  };

  const deleteCla = async (id) => {
    try {
      setDelete(true);
      const feed = await deleteClass(id);
      setDelete(false);
      if (feed.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      setDelete(false);
      console.log(error);
    }
  };

  const noClassStudents = (id) => {
    let number = 0;
    students.forEach((stude) => {
      if (stude.class === id) {
        number = number + 1;
      }
    });
    return number;
  };

  const head = ["Class Name", "No. of Students", "Class Subjects", "Action"];

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <CardTitle tag="h4">Class List</CardTitle>
                <Form inline className="ml-auto">
                  <FormGroup className="no-border">
                    <Input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => updateSearch(e.target.value)}
                    />
                  </FormGroup>
                </Form>
                <button
                  style={{
                    fontWeight: "bold",
                    marginRight: 10,
                    marginLeft: 40,
                  }}
                  type="button"
                  onClick={toggle}
                  className=" btn btn-info btn-sm "
                >
                  <i style={{ fontSize: 20 }} className="fas fa-plus-circle" />
                </button>
              </CardHeader>
              <CardBody>
                <Table responsive bordered>
                  <thead>
                    <tr>
                      {head.map((prop, key) => {
                        return (
                          <th
                            key={key}
                            className="text-left"
                            style={{ fontWeight: "bold", fontSize: 14 }}
                          >
                            {prop}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  {search ? (
                    <>
                      {search.map((darasa) => (
                        <tbody>
                          <td>{darasa.name}</td>
                          <td>{noClassStudents(darasa._id)}</td>
                          <td>
                            {darasa.subjects.map((dar) => (
                              <div>{dar.name},</div>
                            ))}
                          </td>

                          <td>
                            <button
                              style={{ fontWeight: "bold", marginRight: 10 }}
                              type="button"
                              onClick={() => toggle1(darasa)}
                              className="btn btn-warning btn-sm "
                            >
                              <i className="far fa-edit"></i>
                            </button>
                            <button
                              style={{ fontWeight: "bold" }}
                              type="button"
                              onClick={() => deleteCla(darasa._id)}
                              className="btn btn-danger btn-sm "
                            >
                              <i
                                className={
                                  isDelete ? "far fa-flag" : "far fa-trash-alt"
                                }
                              ></i>
                            </button>
                          </td>
                        </tbody>
                      ))}
                    </>
                  ) : null}
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal isOpen={modal} toggle={toggle} backdrop={false}>
          <ModalHeader toggle={toggle}>Add New Class</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <label>New Class</label>
                  <Input
                    type="text"
                    onChange={(e) => setclassname(e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col md="12" style={{ marginTop: 20 }}>
                <label>Select class Subjects:</label>
                {subjects.length > 0 ? (
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    onChange={(e) => setSubjectSelected(e)}
                    options={subjectOptions}
                  />
                ) : (
                  <div style={{ color: "orange" }}>
                    Please you need to add subject before adding class. Add
                    subject on Subjects tab.
                  </div>
                )}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="info"
              onClick={addClass}
              disabled={isSubmiting}
              style={{ fontWeight: "bold" }}
            >
              Add Class
            </Button>{" "}
            <Button
              color="secondary"
              onClick={toggle}
              style={{ fontWeight: "bold" }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modal1} toggle={toggle1} backdrop={false}>
          <ModalHeader toggle={toggle1}>Update Class</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <label>Class name</label>
                  <Input
                    type="text"
                    defaultValue={defaultName}
                    onChange={(e) => setNewName(e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col md="12" style={{ marginTop: 20 }}>
                <label>Select Subjects:</label>

                <Select
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  defaultValue={defaultSubs}
                  isMulti
                  onChange={(e) => setnewSubjectSelected(e)}
                  options={subjectOptions}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="info"
              onClick={updateToNewClass}
              disabled={isSubmiting}
              style={{ fontWeight: "bold" }}
            >
              Update Class
            </Button>{" "}
            <Button
              color="secondary"
              onClick={toggle1}
              style={{ fontWeight: "bold" }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default ClassView;
