import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
} from "reactstrap";
import styled from "styled-components";
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import {
  getSchool,
  getTypeFromClass,
  getOneStudent,
  getResultsOfOneStudent,
  getOneClass,
  getClassResults,
  getClassStudents,
} from "../controller";
import { useTable } from "react-table";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;
    width: 100%;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th style={{ textAlign: "center" }} {...column.getHeaderProps()}>
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

const ReportScreen = ({ location }) => {
  const [student, setStudent] = React.useState();
  const [allResults, setAllResults] = React.useState([]);
  const [exams, setExams] = React.useState([]);
  const [dataReport, setDataReport] = React.useState([]);
  const [tokeo, setTokeo] = React.useState(null);
  const [typeEx, setTypeEx] = React.useState(null);
  const [subjects, setSubjects] = React.useState([]);
  const [school, setSchool] = React.useState(null);
  const [exTy, setExTy] = React.useState(null);
  const [classStudents, setClassStudents] = React.useState(0);

  const calculateGrade = (mark) => {
    if (school && school.level === "secondary") {
      switch (true) {
        case mark >= 75:
          return "A";
        case mark >= 65 && mark < 75:
          return "B";
        case mark >= 45 && mark < 65:
          return "C";
        case mark >= 30 && mark < 45:
          return "D";
        case mark >= 0 && mark < 29:
          return "F";
        default:
          break;
      }
    } else if (school && school.level === "primary") {
      switch (true) {
        case mark >= 81:
          return "A";
        case mark >= 61 && mark < 81:
          return "B";
        case mark >= 41 && mark < 61:
          return "C";
        case mark >= 21 && mark < 41:
          return "D";
        case mark >= 0 && mark < 21:
          return "F";
        default:
          break;
      }
    }
  };

  const calculateRemarks = (mark) => {
    if (school && school.level === "secondary") {
      switch (true) {
        case mark >= 75:
          return "Excellent";
        case mark >= 65 && mark < 75:
          return "Very good";
        case mark >= 45 && mark < 65:
          return "Average";
        case mark >= 30 && mark < 45:
          return "Satisfaction";
        case mark >= 0 && mark < 29:
          return "Fail";
        default:
          break;
      }
    } else if (school && school.level === "primary") {
      switch (true) {
        case mark >= 81:
          return "Excellent";
        case mark >= 61 && mark < 81:
          return "Very good";
        case mark >= 41 && mark < 61:
          return "Average";
        case mark >= 21 && mark < 41:
          return "Satisfaction";
        case mark >= 0 && mark < 21:
          return "Fail";
        default:
          break;
      }
    }
  };

  const getMonth = (month) => {
    switch (month) {
      case "1":
        return "January";
      case "2":
        return "February";
      case "3":
        return "March";

      case "4":
        return "April";

      case "5":
        return "May";

      case "6":
        return "June";

      case "7":
        return "July";

      case "8":
        return "August";

      case "9":
        return "September";

      case "10":
        return "October";

      case "11":
        return "November";

      case "12":
        return "December";

      default:
        break;
    }
  };

  const getMonthList = () => {
    let list = [];
    dataReport.forEach((resp) => {
      if (
        resp.name.split("-")[1].trim().split("/")[0].trim() === "5" ||
        resp.name.split("-")[1].trim().split("/")[0].trim() === "6" ||
        resp.name.split("-")[1].trim().split("/")[0].trim() === "11" ||
        resp.name.split("-")[1].trim().split("/")[0].trim() === "12"
      ) {
        return;
      } else {
        list.push({
          Header:
            getMonth(resp.name.split("-")[1].trim().split("/")[0].trim()) +
            "(%)",
          accessor: getMonth(resp.name.split("-")[1].split("/")[0].trim()),
        });
      }
    });

    return list;
  };

  const columns = [
    {
      Header: "SUBJECT",
      columns: [{ Header: "", accessor: "sub" }],
    },
    {
      Header: "MONTHLY EXAMS",
      columns: getMonthList(),
    },
    {
      Header: `${typeEx}(%)`,
      columns: [{ Header: "", accessor: "mid" }],
    },
    {
      Header: "TOTAL",
      columns: [{ Header: "", accessor: "total" }],
    },
    {
      Header: "AVER(%)",
      columns: [{ Header: "", accessor: "average" }],
    },
    {
      Header: "GRADE",
      columns: [{ Header: "", accessor: "grade" }],
    },
    {
      Header: "REMARKS",
      columns: [{ Header: "", accessor: "remarks" }],
    },
  ];

  const addMoreData = (arr) => {
    let newArr = [...arr];
    arr.map((one) => {
      let total = 0;
      let idadi = 0;
      Object.keys(one).forEach((key) => {
        if (typeof one[key] === "number") {
          total += one[key];
          idadi++;
        }
      });

      newArr.push({
        total,
        ...one,
        average: (total / idadi).toFixed(2),
        grade: calculateGrade(total / idadi),
        remarks: calculateRemarks(total / idadi),
      });
    });
    return newArr.slice(newArr.length / 2);
  };

  const findData = () => {
    let list = [];

    subjects.forEach((subs) => {
      dataReport.forEach((data) => {
        data.exams.forEach((da) => {
          list.push({
            sub: subs.name,
          });

          if (data.name.split("-")[0].trim() === "Monthly") {
            var index = list.findIndex(
              (obj) => obj.sub == da.exam.subject.name
            );
            list[index] = {
              [getMonth(data.name.split("-")[1].trim().split("/")[0].trim())]:
                da.marks,
              ...list[index],
            };
          }
          if (data.name.split("-")[0].trim() === typeEx) {
            var index1 = list.findIndex(
              (obj) => obj.sub == da.exam.subject.name
            );
            list[index1] = {
              mid: da.marks,
              ...list[index1],
            };
          }
        });
      });
    });

    let newList = [
      ...new Map(list.reverse().map((item) => [item["sub"], item])).values(),
    ];

    const newData = addMoreData(newList);

    return newData;
  };

  const data = subjects.length > 0 && dataReport.length > 0 ? findData() : [];

  React.useEffect(() => {
    const { state } = location;

    if (!localStorage.getItem("school")) {
      return (window.location.href = "/");
    }
    const profile = JSON.parse(localStorage.getItem("school"));
    setSchool(profile);
    getResultsOfOneStudent(state.name)
      .then((res) => setTokeo(res.data))
      .catch((err) => console.log(err));
    getOneStudent(state.name)
      .then((re) => {
        setStudent(re.data);
        getClassStudents(re.data.class._id)
          .then((response) => setClassStudents(response))
          .catch((err) => console.log(err));
        getTypeFromClass(re.data.class._id)
          .then((response) => setExams(addExams(response.data)))
          .catch((err) => console.log(err));
        getOneClass(re.data.class._id)
          .then((response) => setSubjects(response.data.subjects))
          .catch((err) => console.log(err));
        getClassResults(re.data.class._id, profile._id)
          .then((response) => setAllResults(response))
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));

    getSchool(profile._id).then().catch();
  }, []);

  const twickList = (data) => {
    const list = [];
    data.forEach((sub) =>
      list.push({
        value: sub.name + "-" + sub.year,
        label: sub.name + "-" + sub.year,
      })
    );
    return list;
  };

  const addExams = (jy) => {
    let list = [];
    jy.forEach((ty) => {
      if (ty.name === "Terminal" || ty.name === "Annual") {
        list.push(ty);
      }
    });
    return list;
  };

  const examsOptions = twickList(exams);

  const changeReport = (ex) => {
    let list = [];
    setTypeEx(ex.split("-")[0]);
    const kind = ex.split("-")[0];
    const year = ex.split("-")[1];
    tokeo.forEach((tok) => {
      var arr = tok.name.split("-").map((item) => {
        return item.trim();
      });

      if (kind === "Terminal") {
        if (
          Number(arr[1].split("/")[0]) < 6 &&
          arr[1].split("/")[1].trim().split(" ")[0] === year
        ) {
          list.push(tok);
        }
      } else {
        if (
          Number(arr[1].split("/")[0]) > 5 &&
          arr[1].split("/")[1].trim().split(" ")[0] === year
        ) {
          list.push(tok);
        }
      }
    });

    const tg = list.sort(
      (a, b) =>
        Number(a.name.split("-")[1].trim()) -
        Number(b.name.split("-")[1].trim())
    );

    return setDataReport(tg);
  };

  const totalMarks = () => {
    let total = 0;
    let sum = 0;
    let all = 0;
    let average = 0;

    dataReport.forEach((repo) => {
      repo.exams.forEach((ex) => {
        total += ex.marks;
        sum += 100;
        all += 1;
      });
    });
    average = (total / all).toFixed(2);

    return { total, sum, average };
  };

  const rankStudents = (tot) => {
    let newList = [];
    let myRank = 1;
    let myTotal = tot;

    const kind = exTy.split("-")[0];
    const year = exTy.split("-")[1];
    allResults?.forEach((tok) => {
      var arr = tok.name.split("-").map((item) => {
        return item.trim();
      });
      if (kind === "Terminal") {
        if (
          Number(arr[1].split("/")[0]) < 6 &&
          arr[1].split("/")[1].trim().split(" ")[0] === year
        ) {
          newList.push(tok);
        }
      } else {
        if (
          Number(arr[1].split("/")[0]) > 5 &&
          arr[1].split("/")[1].trim().split(" ")[0] === year
        ) {
          newList.push(tok);
        }
      }
    });

    const mergedArray = Array.from(
      newList
        .reduce(
          (entryMap, e) =>
            entryMap.set(e.student._id, {
              ...(entryMap.get(e.student._id) || {}),
              ...e,
            }),
          new Map()
        )
        .values()
    );
    let brand = [];
    mergedArray.map((one) => {
      newList.map((li) => {
        if (li.student._id === one.student._id) {
          brand.push({ id: li.student._id, li });
        }
      });
    });

    const arrayHashmap = brand.reduce((obj, item) => {
      obj[item.id]
        ? obj[item.id].li.exams.push(...item.li.exams)
        : (obj[item.id] = { ...item });
      return obj;
    }, {});

    const mergedArray1 = Object.values(arrayHashmap);

    mergedArray1.forEach((oneArray) => {
      let total = 0;
      if (oneArray.id !== student._id) {
        oneArray.li.exams.forEach((ele) => {
          total += ele.marks;
        });
      }

      if (myTotal < total) {
        myRank = myRank + 1;
      }
    });

    return myRank;
  };

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <CardTitle tag="h4">Student's Report</CardTitle>
                {exams.length > 0 ? (
                  <Form inline className="ml-auto">
                    <FormGroup className="no-border">
                      <Select
                        closeMenuOnSelect
                        components={animatedComponents}
                        placeholder="Select Report ..."
                        onChange={(e) => {
                          changeReport(e.value);
                          setExTy(e.value);
                        }}
                        options={examsOptions}
                      />
                    </FormGroup>
                  </Form>
                ) : (
                  <p>Loading...</p>
                )}
                <button
                  style={{
                    fontWeight: "bold",
                    marginRight: 10,
                    marginLeft: 40,
                  }}
                  type="button"
                  class=" btn btn-secondary btn-sm "
                >
                  <i style={{ fontSize: 15 }} class="fas fa-download" />
                </button>
              </CardHeader>
              {typeEx ? (
                <CardBody>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: 23,
                      fontWeight: "bold",
                    }}
                  >
                    {student.school.name}
                  </div>
                  <Styles>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>
                          Student Name:
                          <span style={styles.lineDown}>
                            {student.name}
                          </span>{" "}
                        </p>
                        <p>
                          Admission Number:
                          <span style={styles.lineDown}>
                            {student.adno}
                          </span>{" "}
                        </p>
                      </div>
                      <p>
                        Number of school days:
                        <span style={styles.lineDown}>101</span>{" "}
                      </p>
                      <p>
                        Number of absent days:
                        <span style={styles.lineDown}>0</span>{" "}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>
                          Class:
                          <span style={styles.lineDown}>
                            {student.class.name}
                          </span>{" "}
                        </p>
                        <p>
                          Term:
                          <span style={styles.lineDown}>
                            {typeEx === "Terminal" ? 1 : 2}
                          </span>{" "}
                        </p>
                        <p>
                          Year:
                          <span style={styles.lineDown}>
                            {new Date().getFullYear()}
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                        fontWeight: "bold",
                        fontSize: 20,
                      }}
                    >
                      ACADEMIC PROGRESS
                    </div>
                    <Table columns={columns} data={data} />
                    <div style={{ marginBottom: 50, marginTop: 50 }}>
                      <div style={styles.align}>
                        <p>
                          Total Marks:
                          <span style={styles.lineDown}>
                            {totalMarks().total}
                          </span>{" "}
                        </p>
                        <p>
                          Out of:
                          <span style={styles.lineDown}>
                            {totalMarks().sum}
                          </span>{" "}
                        </p>
                      </div>
                      <div style={styles.line} />
                      <div style={styles.align}>
                        <p>
                          Average:
                          <span style={styles.lineDown}>
                            {totalMarks().average}%
                          </span>{" "}
                        </p>
                        <p>
                          Grade:
                          <span style={styles.lineDown}>
                            {calculateGrade(totalMarks().average)}
                          </span>{" "}
                        </p>
                      </div>
                      <div style={styles.line} />
                      <div style={styles.align}>
                        <p>
                          Position in class:
                          <span style={styles.lineDown}>
                            {allResults.length > 0
                              ? rankStudents(totalMarks().total)
                              : null}
                          </span>{" "}
                        </p>
                        <p>
                          Out of:
                          <span style={styles.lineDown}>
                            {classStudents}
                          </span>{" "}
                        </p>
                      </div>
                      <div style={styles.line} />
                      <div style={styles.align}>
                        <p>
                          School pass marks:
                          <span style={styles.lineDown}>61%</span>{" "}
                        </p>
                        <p></p>
                      </div>
                      <div style={styles.line} />
                      <div style={styles.align}>
                        <p>Promoted to / Not promoted to:</p>
                        <p></p>
                      </div>
                      <div style={styles.line} />
                      <div style={styles.align}>
                        <p>Class teacher's remarks:</p>
                        <p style={{ fontWeight: "bold" }}>
                          {calculateRemarks(totalMarks().average)}
                        </p>
                      </div>
                      <div style={styles.line} />
                    </div>
                    <p style={{ marginBottom: 0, fontWeight: "bold" }}>
                      Primary
                    </p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>A = 100 - 81 Every Good</p>
                      <p>B = 80 - 61 Good</p>
                      <p>C = 60 - 41 Average</p>
                      <p>D = 40 - 21 Satistactory</p>
                      <p>F = 20 - 0 Poor</p>
                    </div>

                    <p style={{ marginBottom: 0, fontWeight: "bold" }}>
                      Secondary
                    </p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>A = 100 - 75 Every Good</p>
                      <p>B = 74 - 65 Good</p>
                      <p>C = 64 - 45 Average</p>
                      <p>D = 45 - 30 Satistactory</p>
                      <p>F = 29 - 0 Poor</p>
                    </div>
                  </Styles>
                </CardBody>
              ) : null}
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const styles = {
  lineDown: {
    marginLeft: 20,
    fontWeight: "bold",
    textDecorationLine: "underline",
  },
  align: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "20%",
    paddingRight: "20%",
  },
  line: {
    marginBottom: 10,
    width: "100%",
    height: 2,
    backgroundColor: "#eee",
  },
};

export default ReportScreen;
