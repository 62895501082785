import Dashboard from "views/Dashboard.js";
import Students from "views/Students";
import Classes from "views/Classes";
import Results from "views/Results";
import Exams from "views/Exams";
import UserPage from "views/UserPage.js";
import Subjects from "views/Subjects";
import ExamType from 'views/ExamType'
import SeeResults from 'views/SeeResult'
import SMSScreen from 'views/SMSpage'
import ReportScreen from "views/Report";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "design_app",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/classes",
    name: "Classes",
    icon: "design_bullet-list-67",
    component: Classes,
    layout: "/admin",
  },
  {
    path: "/subjects",
    name: "Subjects",
    icon: "media-1_album",
    component: Subjects,
    layout: "/admin",
  },
  {
    path: "/students",
    name: "Students",
    icon: "users_single-02",
    component: Students,
    layout: "/admin",
  },

  {
    path: "/examtype",
    name: "Exam Type",
    icon: "ui-2_settings-90",
    component: ExamType,
    layout: "/admin",
  },

  {
    path: "/exams",
    name: "Exams",
    icon: "files_single-copy-04",
    component: Exams,
    layout: "/admin",
  },
  {
    path: "/results",
    name: "Results",
    icon: "files_paper",
    component: Results,
    layout: "/admin",
  },
  {
    path: "/sms",
    name: "SMS",
    icon: "ui-1_send",
    component: SMSScreen,
    layout: "/admin",
  },
  {
    path: "/user-page",
    name: "Profile",
    icon: "design-2_ruler-pencil",
    component: UserPage,
    layout: "/admin",
  },
  {
    path: "/seeresult",
    name: "",
    icon: "",
    component: SeeResults,
    layout: "/admin",
  },
  {
    path: "/report",
    name: "",
    icon: "",
    component: ReportScreen,
    layout: "/admin",
  },
];
export default dashRoutes;
