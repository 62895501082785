import React from "react";
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  getAllSMSKind,
  addSMSkind,
  sendSMSToMany,
  getAllSMS,
  getStudents,
  getClasses,
  saveMessage,
} from "../controller";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const SMSPage = () => {
  const styles = {
    para: {
      marginRight: 5,
      fontWeight: 600,
      fontStyle: "italic",
    },
  };

  const [kinds, setkinds] = React.useState([]);
  const [smss, setsms] = React.useState([]);
  const [modal1, setModal1] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  const [kindname, setkindname] = React.useState(null);
  const [isSubmiting, setSubmiting] = React.useState(false);
  const [message, setMessage] = React.useState(null);
  const [err, setError] = React.useState(null);
  const [pass, setPass] = React.useState(null);
  const [search, setSearch] = React.useState(null);
  const [selectedKind, setSelectedkind] = React.useState(null);
  const [students, setStudents] = React.useState([]);
  const [student, setStudent] = React.useState([]);
  const [newStudents, setnewStudents] = React.useState([]);
  const [madarasa, setMadarasa] = React.useState([]);
  const [isDelete, setDelete] = React.useState(false);

  React.useEffect(() => {
    if (!localStorage.getItem("school")) {
      return (window.location.href = "/");
    }
    const profile = JSON.parse(localStorage.getItem("school"));
    getAllSMSKind(profile._id)
      .then((res) => {
        if (res.status === 200) {
          setkinds(res.data);
        }
      })
      .catch((err) => console.log(err));
    getAllSMS(profile._id).then((res) => {
      if (res.status === 200) {
        setsms(res.data);
        setSearch(res.data);
      }
    });
    getStudents(profile._id)
      .then((re) => setStudents(re.data))
      .catch((err) => console.log(err));
    getClasses(profile._id)
      .then((re) => setMadarasa(re.data))
      .catch((err) => console.log(err));
  }, []);

  const twickSMSs = (data) => {
    const list = [];
    data.forEach((sub) => list.push({ value: sub._id, label: sub.name }));
    return list;
  };

  const twickList = (data) => {
    const list = [];
    data.forEach((sub) =>
      list.push({
        value: sub._id,
        parentphone: sub.parentPhone ? sub.parentPhone : null,
        label: sub.name,
      })
    );
    return list;
  };

  const kindOptions = twickSMSs(kinds);
  const classOption = twickList(madarasa);

  const toggle1 = () => {
    setModal1(!modal1);
  };
  const toggle2 = () => {
    setModal2(!modal2);
  };

  const addKind = async () => {
    try {
      const profile = JSON.parse(localStorage.getItem("school"));

      if (!kindname) {
        return setError("Please fill type name");
      }
      setSubmiting(true);
      const body = {
        school: profile._id,
        name: kindname,
      };
      const feed = await addSMSkind(body);
      setSubmiting(false);
      if (feed.status === 201) {
        return window.location.reload();
      } else {
        return setError("Failed to add type, Please try again");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchStudent = (id) => {
    let newStuds = [];
    students.forEach((st) => {
      if (st.class._id === id) {
        newStuds.push(st);
      }
    });
    setnewStudents(twickList(newStuds));
  };

  const sendMessage = async () => {
    try {
        const profile = JSON.parse(localStorage.getItem("school"));
        //console.log(message, student, selectedKind)
      if (!message || student.length < 1 || !selectedKind) {
        return setError("Fill all fields.");
      }
      setSubmiting(true);
      setError(null);
      let numbers = [];
      let studs = [];
      student.map((stu) => {
        let number;
       
        if (stu.parentphone) {
          if (stu.parentphone.toString()[0] !== "2") {
            number = `255${stu.parentphone}`;
          } else {
            number = `${stu.parentphone}`;
          }
          numbers.push(number);
          studs.push(stu.value);
        }
      });

      const body = {
        to: numbers,
        text: message,
      };
      const body2 = {
        message: message,
        from: profile._id,
        to: studs,
        kind: selectedKind,
      };
      const feedback = await sendSMSToMany(body);
  
      if (feedback.messages) {
        setPass("SMS sent successfully");
        await saveMessage(body2);
        setSubmiting(false)
        return window.location.reload();
      } else {
        setSubmiting(false)
        return setError("Failed to send SMS. Try again");
      }
    } catch (error) {
        setSubmiting(false)
      console.log(error);
    }
  };

  console.log(smss);

  const head = ["Kind", "Message", "Reciepients", "Sent At"];

  return (
    <div className="wrapper">
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <CardTitle tag="h4">SMS Manager</CardTitle>
                <Form inline className="ml-auto">
                  <FormGroup className="no-border">
                    <button
                      style={{
                        fontWeight: "bold",
                        marginRight: 10,
                        marginLeft: 40,
                      }}
                      onClick={toggle2}
                      type="button"
                      className=" btn btn-info btn-sm "
                    >
                      <i
                        style={{ fontSize: 20 }}
                        className="fas fa-plus-circle"
                      />
                    </button>
                  </FormGroup>
                </Form>
              </CardHeader>
              <CardBody>
                <div>
                  <hr />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "bold",
                        color: "green",
                        textAlign: "center",
                      }}
                    >
                      Types (Kinds) of SMS
                    </p>
                    <button
                      style={{
                        fontWeight: "bold",
                        marginLeft: 20,
                      }}
                      onClick={toggle1}
                      type="button"
                      className="btn btn-success btn-sm "
                    >
                      <i style={{ fontSize: 10 }} className="fas fa-plus" />
                    </button>
                  </div>
                  {kinds.length > 0 ? (
                    <div
                      style={{
                        margin: "20,20,0,0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {kinds.map((kind) => (
                        <p style={styles.para}>{kind?kind.name:'N/A'},</p>
                      ))}
                    </div>
                  ) : (
                    <p style={{ textAlign: "center", color: "orange" }}>
                      There is no an kind/type so far. You can add type of SMS.
                    </p>
                  )}
                  <hr />
                </div>
                <Table responsive bordered>
                  <thead>
                    <tr>
                      {head.map((prop, key) => {
                        return (
                          <th
                            key={key}
                            className="text-left"
                            style={{ fontWeight: "bold", fontSize: 14 }}
                          >
                            {prop}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                    {search?
                  <>
                    {search.map((ms) => (
                        <tbody key={ms._id}>
                          <td>{ms.kind? ms.kind.name:'N/A'}</td>
                          <td>{ms.message}</td>
                          <td>
                            {ms.to.map((dar) => (
                              <div>{dar.name},</div>
                            ))}
                          </td>
                          <td>{new Date(ms.createdAt).toLocaleString()}</td>
                         
                          
                        </tbody>
                      ))}
                  </>:null}
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal isOpen={modal1} toggle={toggle1} backdrop={false}>
        <ModalHeader toggle={toggle1}>Add New Kind/Type of SMS</ModalHeader>
        <ModalBody>
          <Row>
            {err ? (
              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <p style={{ textAlign: "center" }} className="text-danger">
                    {err}
                  </p>
                </FormGroup>
              </Col>
            ) : null}
            <Col md="12" style={{ marginTop: 20 }}>
              <FormGroup>
                <label>Type Name</label>
                <Input
                  type="text"
                  placeholder="Announcement / School fee / Exams Result     e.t.c"
                  onChange={(e) => {
                    setkindname(e.target.value);
                    setError(null);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="info"
            onClick={addKind}
            disabled={isSubmiting}
            style={{ fontWeight: "bold" }}
          >
            Add Kind
          </Button>{" "}
          <Button
            color="secondary"
            onClick={toggle1}
            style={{ fontWeight: "bold" }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modal2} toggle={toggle2} backdrop={false}>
        <ModalHeader toggle={toggle2}>Send SMS</ModalHeader>
        <ModalBody>
          <Row>
            {err ? (
              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <p style={{ textAlign: "center" }} className="text-danger">
                    {err}
                  </p>
                </FormGroup>
              </Col>
            ) : null}
            {pass ? (
              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <p
                    style={{ textAlign: "center", fontWeight: "bold" }}
                    className="text-success"
                  >
                    {pass}
                  </p>
                </FormGroup>
              </Col>
            ) : null}
            <Col md="12" style={{ marginTop: 20 }}>
              <FormGroup>
                <label>Select Type/Kind</label>

                <Select
                  closeMenuOnSelect
                  components={animatedComponents}
                  onChange={(e) => {
                    setSelectedkind(e.value);
                    setError(null);
                  }}
                  options={kindOptions}
                />
              </FormGroup>
            </Col>

            <Col md="12" style={{ marginTop: 20 }}>
              <FormGroup>
                <label>Select Class</label>
                {madarasa.length > 0 ? (
                  <Select
                    closeMenuOnSelect
                    components={animatedComponents}
                    onChange={(e) => {
                      fetchStudent(e.value);
                      setError(null);
                    }}
                    options={classOption}
                  />
                ) : (
                  <div style={{ color: "blue" }}>Loading...</div>
                )}
              </FormGroup>
            </Col>

            <Col md="12" style={{ marginTop: 20 }}>
              <FormGroup>
                <label>Select Student</label>
                {newStudents.length > 0 ? (
                  <Select
                    closeMenuOnSelect={false}
                    isMulti
                    components={animatedComponents}
                    onChange={(e) => {
                      setStudent(e);
                      setError(null);
                    }}
                    options={newStudents}
                  />
                ) : (
                  <div style={{ color: "blue" }}>Loading...</div>
                )}
              </FormGroup>
            </Col>
            <Col md="12" style={{ marginTop: 20 }}>
              <FormGroup>
                <lable style={{margin:'0, 0, 30px,0'}}>Message</lable>
                <Input
                style={{fontWeight: 'bold', backgroundColor:'#f5f5f5'}}
                  type="textarea"
                  placeholder="Write your message here..."
                  onChange={(e) => {
                    setMessage(e.target.value);
                    setError(null);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={sendMessage}
            disabled={isSubmiting}
            style={{ fontWeight: "bold" }}
          >
            Send Now
          </Button>
          <Button
            color="secondary"
            onClick={toggle2}
            style={{ fontWeight: "bold" }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SMSPage;
