import axios from "axios";

export default axios.create({
  baseURL: "https://schoolbara.herokuapp.com/api",
});

// export default axios.create({
//    baseURL: "http://67.205.184.61:5000/api",
// });


