import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Link } from "react-router-dom";
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import {
  getSchool,
  getStudents,
  getClasses,
  addNewStudent,
  deleteStudent,
  updateNewStudent,
  sendSMSToMany,
  saveMessage,
} from "../controller";

import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

function StudentView() {
  const [students, setStudents] = React.useState([]);
  const [shule, setShule] = React.useState(null);
  const [modal, setModal] = React.useState(false);
  const [modal1, setModal1] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  const [isSubmiting, setSubmiting] = React.useState(false);
  const [madarasa, setMadarasa] = React.useState([]);
  const [adNo, setAdNo] = React.useState(null);
  const [name, setName] = React.useState(null);
  const [classID, setClassID] = React.useState(null);
  const [parentPhone, setParentPhone] = React.useState(null);
  const [isDelete, setDelete] = React.useState(false);
  const [err, setError] = React.useState(null);
  const [pass, setPass] = React.useState(null);
  const [isChecked, setIsChecked] = React.useState(false);
  const [isSending, setIsSending] = React.useState(false);

  //updated
  const [selectID, setSelectID] = React.useState(null);
  const [newDob, setnewDob] = React.useState(null);
  const [newclassID, setnewClassID] = React.useState(null);
  const [newemail, setnewEmail] = React.useState(null);
  const [newBalance, setnewBalance] = React.useState(null);
  const [newParentEmail, setnewParentEmail] = React.useState(null);
  const [newParentPhone, setnewParentPhone] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const [search, setSearch] = React.useState(null);
  const [selected, setSelected] = React.useState([]);

  const toggle = () => {
    setError(null);
    setModal(!modal);
  };
  const toggle1 = () => {
    setError(null);
    setModal1(!modal1);
  };

  const toggle2 = () => {
    setError(null);
    setModal2(!modal2);
  };

  React.useEffect(() => {
    if (!localStorage.getItem("school")) {
      return (window.location.href = "/");
    }
    const profile = JSON.parse(localStorage.getItem("school"));
    setShule(profile);
    getStudents(profile._id)
      .then((re) => {
        setStudents(re.data);
        setSearch(re.data);
      })
      .catch((err) => console.log(err));
    getSchool(profile._id).then().catch();
    getClasses(profile._id)
      .then((re) => {
        setMadarasa(re.data);
        setClassID(`${re.data[0].name}/${re.data[0]._id}`);
      })
      .catch((err) => console.log(err));
  }, []);

  const twikList = (data) => {
    const list = [];
    data.forEach((sub) => list.push({ value: sub._id, label: sub.name }));
    return list;
  };

  const classOptions = twikList(madarasa);

  const updateSearch = (text) => {
    if (text === "") {
      return setSearch(students);
    }

    const regexp = new RegExp(text, "i");
    const filtered = students.filter((x) => regexp.test(x.name));
    return setSearch(filtered);
  };

  const updateSearchByClass = (id) => {
    if (id === "") {
      return setSearch(students);
    }

    const regexp = new RegExp(id, "i");
    const filtered = students.filter((x) => regexp.test(x.class._id));
    return setSearch(filtered);
  };

  const addStudent = async () => {
    try {
      const profile = JSON.parse(localStorage.getItem("school"));
      if (!adNo || !name) {
        return alert("Admission number and student's name are required");
      }
      setSubmiting(true);
      const body = {
        name: name,
        adno: adNo,
        class: classID.split("/")[1],
        school: profile._id,
        parentPhone: parentPhone ? parentPhone : null,
      };
      const feed = await addNewStudent(body);
      setSubmiting(false);
      if (feed.status === 201) {
        return window.location.reload();
      } else {
        return alert("Failed to add class, Please try again");
      }
    } catch (error) {
      setSubmiting(false);
      console.log(error);
    }
  };

  const updateStu = async () => {
    try {
      setSubmiting(true);
      const body = {
        class: newclassID ? newclassID.split("/")[1] : selectID.class._id,
        email: newemail ? newemail : selectID.email,
        totalFees: newBalance ? Number(newBalance) : selectID.feeBalance,
        dob: newDob ? newDob : selectID.dob,
        parentPhone: newParentPhone
          ? Number(newParentPhone)
          : selectID.parentPhone,
        parentEmail: newParentEmail ? newParentEmail : selectID.parentEmail,
      };
      const feed = await updateNewStudent(selectID._id, body);
      setSubmiting(false);
      if (feed.status === 202) {
        return window.location.reload();
      } else {
        return alert("Failed to add class, Please try again");
      }
    } catch (error) {
      setSubmiting(false);
      console.log(error);
    }
  };

  const deleteStu = async (id) => {
    try {
      setDelete(true);
      const feed = await deleteStudent(id);
      setDelete(false);
      if (feed.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      setDelete(false);
      console.log(error);
    }
  };

  const onChangeBox = (id) => {
    let select = selected;
    let find = select.indexOf(id);
    if (find > -1) {
      select.splice(find, 1);
    } else {
      select.push(id);
    }
    return setSelected(select);
  };

  let defaultSMS = `Ndugu Mzazi/Mlezi.\n\n${
    shule ? shule.name : ""
  } inakukumbusha kumalizia ulipaji wa karo ya shule ya ${
    selectID ? selectID.name : " "
  }.\n\nKiasi: ${
    selectID && selectID.feeBalance
      ? selectID.feeBalance.toLocaleString("en")
      : "..."
  } Tsh\n\nAsante kwa ushirikiano. Uwe na wakati mzuri.
  `;

  const tumaSms = async () => {
    try {
      if (!selectID.parentPhone) {
        return setError(
          "Make sure there is parent's phone number and message is filled"
        );
      }
      setSubmiting(true);
      setError(null);
      const body = {
        to: selectID.parentPhone,
        text: message ? message : defaultSMS,
      };
      const body2 = {
        message: message ? message : defaultSMS,
        from: shule._id,
        to: [selectID._id],
      };
      const feedback = await sendSMSToMany(body);

      if (feedback.messages) {
        setPass("SMS sent successfully");
        await saveMessage(body2);
        setSubmiting(false);
        return window.location.reload();
      } else {
        setSubmiting(false);
        return setError("Failed to send SMS. Try again");
      }
    } catch (error) {
      setSubmiting(false);
      console.log(error);
    }
  };

  const sendToOne = async (to, mwanafunzi, fee) => {
    let message = `Ndugu Mzazi/Mlezi.\n\n${
      shule ? shule.name : ""
    } inakukumbusha kumalizia ulipaji wa karo ya shule ya ${mwanafunzi}.\n\nKiasi: ${fee.toLocaleString(
      "en"
    )} Tsh\n\nAsante kwa ushirikiano. Uwe na wakati mzuri.
    `;
    try {
      const body = {
        to: to,
        text: message,
      };
      const body2 = {
        message: message,
        from: shule._id,
        to: to,
      };

      const feedback = await sendSMSToMany(body);
      if (feedback.messages) {
        await saveMessage(body2);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sendSMSToAll = async () => {
    try {
      setIsSending(true);
      let num = 0;
      for (let i = 0; i < search.length; i++) {
        if (search[i].feeBalance && search[i].parentPhone) {
          sendToOne(
            `${search[i].parentPhone}`,
            search[i].name,
            search[i].feeBalance
          ).then((e) => {
            console.log(e);
          });
        }
        num = num + 1;
      }
      if (num === search.length) {
        setIsSending(false);
        setIsChecked(false);
        return alert("SMS sent successfully");
      }
    } catch (error) {
      setIsSending(false);
      setIsChecked(false);
      console.log(error);
      return alert("Failed try again");
    }
  };

  const head = [
    <input
      type="checkbox"
      onChange={(e) => setIsChecked(!isChecked)}
      checked={isChecked}
    />,
    "Ad.no",
    "Name",
    "Class",
    "Total Fees",
    "Reports",
    "Parent Phone",
    "Action",
  ];

  const linkURL = `https://fees.baraapp.org?${shule?._id}`;

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <CardTitle tag="h4">Students List</CardTitle>
                <Form inline className="ml-auto">
                  <FormGroup className="no-border">
                    <Select
                      closeMenuOnSelect
                      components={animatedComponents}
                      placeholder="Select Class ..."
                      onChange={(e) => {
                        updateSearchByClass(e.value);
                      }}
                      options={classOptions}
                    />
                  </FormGroup>
                </Form>
                <Form inline className="ml-auto">
                  <FormGroup className="no-border">
                    <Input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => updateSearch(e.target.value)}
                    />
                  </FormGroup>
                </Form>
                <button
                  style={{
                    fontWeight: "bold",
                    marginRight: 10,
                    marginLeft: 40,
                  }}
                  type="button"
                  onClick={toggle}
                  class=" btn btn-info btn-sm "
                >
                  <i style={{ fontSize: 20 }} class="fas fa-plus-circle" />
                </button>
              </CardHeader>
              <div style={{ margin: "20px 0" }}>
                <h6
                  style={{
                    textAlign: "center",
                    cursor: "pointer",
                    color: "blue",
                    textDecorationLine: "underline",
                  }}
                >
                  <a href={linkURL} target="_blank">
                    {" "}
                    GO TO FEE MANAGEMENT SYSTEM
                  </a>{" "}
                </h6>
              </div>
              {isChecked ? (
                <div style={{ marginLeft: 20, display: "flex" }}>
                  {!isSending ? (
                    <button
                      style={{ fontWeight: "bold" }}
                      type="button"
                      className="btn btn-secondary btn-sm"
                      onClick={() => sendSMSToAll()}
                    >
                      Send fee balance SMS to all
                    </button>
                  ) : (
                    <button
                      style={{ fontWeight: "bold" }}
                      type="button"
                      className="btn btn-secondary btn-sm"
                      disabled
                    >
                      Loading...
                    </button>
                  )}
                </div>
              ) : null}
              <CardBody>
                <Table responsive bordered>
                  <thead>
                    <tr>
                      {head.map((prop, key) => {
                        return (
                          <th
                            key={key}
                            className="text-left"
                            style={{ fontWeight: "bold", fontSize: 14 }}
                          >
                            {prop}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  {search ? (
                    <>
                      {search.map((student) => (
                        <tbody key={student._id}>
                          <td>
                            <input type="checkbox" checked={isChecked} />
                          </td>
                          <td>{student.adno}</td>
                          <td>{student.name}</td>
                          <td>
                            {student.class ? student.class.name : "no class"}
                          </td>

                          <td style={{ fontWeight: "bold" }}>
                            {student.totalFees
                              ? `${student.totalFees.toLocaleString("en")} Tsh`
                              : "N/A"}
                          </td>
                          <td>
                            {/* <button
                              style={{ fontWeight: "bold" }}
                              type="button"
                              className="btn btn-success btn-sm"
                              onClick={() => {
                                setSelectID(student);
                                toggle2();
                              }}
                            >
                              Send
                            </button> */}
                            <Link
                              to={{
                                pathname: "../admin/report",
                                state: {
                                  name: student._id,
                                },
                              }}
                            >
                              <button
                                style={{ fontWeight: "bold" }}
                                type="button"
                                className="btn btn-info btn-sm"
                              >
                                View
                              </button>
                            </Link>
                          </td>
                          <td>{student.parentPhone}</td>
                          <td>
                            <button
                              style={{ fontWeight: "bold", marginRight: 10 }}
                              type="button"
                              onClick={() => {
                                setSelectID(student);
                                toggle1();
                              }}
                              class="btn btn-warning btn-sm "
                            >
                              <i class="far fa-edit"></i>
                            </button>
                            <button
                              style={{ fontWeight: "bold" }}
                              type="button"
                              onClick={() => deleteStu(student._id)}
                              class="btn btn-danger btn-sm "
                            >
                              <i
                                className={
                                  isDelete ? "far fa-flag" : "far fa-trash-alt"
                                }
                              ></i>
                            </button>
                          </td>
                        </tbody>
                      ))}
                    </>
                  ) : null}
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal isOpen={modal} toggle={toggle} backdrop={false}>
          <ModalHeader toggle={toggle}>Add New Student</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12" style={{}}>
                <FormGroup>
                  <label>Admission Number</label>
                  <Input
                    type="text"
                    onChange={(e) => setAdNo(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md="12" style={{}}>
                <FormGroup>
                  <label>Student Name</label>
                  <Input
                    type="text"
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md="12" style={{}}>
                <FormGroup>
                  <label>Class</label>
                  {madarasa.length > 0 ? (
                    <>
                      <select
                        style={{ marginLeft: 10 }}
                        onChange={(e) => setClassID(e.target.value)}
                      >
                        {madarasa.map((sub) => (
                          <option
                            value={`${sub.name}/${sub._id}`}
                            label={sub.name}
                            key={sub._id}
                          />
                        ))}
                      </select>
                      <br />
                      <label style={{ color: "green" }}>
                        {classID ? classID.split("/")[0] : ""}
                      </label>
                    </>
                  ) : null}
                </FormGroup>
              </Col>

              <Col md="12" style={{}}>
                <FormGroup>
                  <label>Parent's Phone</label>
                  <Input
                    type="phone-pad"
                    onChange={(e) => setParentPhone(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={addStudent}
              disabled={isSubmiting}
              style={{ fontWeight: "bold" }}
            >
              Add Student
            </Button>
            <Button
              color="secondary"
              onClick={toggle}
              style={{ fontWeight: "bold" }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        {selectID ? (
          <Modal isOpen={modal1} toggle={toggle1} backdrop={false}>
            <ModalHeader toggle={toggle1}>Update Student</ModalHeader>
            <ModalBody>
              <Row>
                <Col md="12" style={{}}>
                  <FormGroup>
                    <label>Class</label>
                    {madarasa.length > 0 ? (
                      <>
                        <select
                          style={{ marginLeft: 10 }}
                          onChange={(e) => setnewClassID(e.target.value)}
                        >
                          {madarasa.map((sub) => (
                            <option
                              value={`${sub.name}/${sub._id}`}
                              label={sub.name}
                              key={sub._id}
                            />
                          ))}
                        </select>
                        <br />
                        <label style={{ color: "green" }}>
                          {newclassID ? newclassID.split("/")[0] : ""}
                        </label>
                      </>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="12" style={{}}>
                  <FormGroup>
                    <label>Email</label>
                    <Input
                      defaultValue={selectID.email ? selectID.email : " "}
                      type="email"
                      onChange={(e) => setnewEmail(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md="12" style={{}}>
                  <FormGroup>
                    <label>Total Fee</label>
                    <Input
                      defaultValue={
                        selectID.feeBalance ? selectID.feeBalance : " "
                      }
                      type="text"
                      onChange={(e) => setnewBalance(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md="12" style={{}}>
                  <FormGroup>
                    <label>Date of Birth</label>
                    <Input
                      defaultValue={selectID.dob ? selectID.dob : " "}
                      type="text"
                      onChange={(e) => setnewDob(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md="12" style={{}}>
                  <FormGroup>
                    <label>Parent's Phone</label>
                    <Input
                      defaultValue={
                        selectID.parentPhone ? selectID.parentPhone : " "
                      }
                      type="phone-pad"
                      onChange={(e) => setnewParentPhone(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md="12" style={{}}>
                  <FormGroup>
                    <label>Parent's Email</label>
                    <Input
                      defaultValue={
                        selectID.parentEmail ? selectID.parentEmail : " "
                      }
                      type="text"
                      onChange={(e) => setnewParentEmail(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                color="info"
                onClick={updateStu}
                disabled={isSubmiting}
                style={{ fontWeight: "bold" }}
              >
                Update Student
              </Button>
              <Button
                color="secondary"
                onClick={toggle1}
                style={{ fontWeight: "bold" }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        ) : null}

        <Modal isOpen={modal2} toggle={toggle2} backdrop={false}>
          <ModalHeader toggle={toggle2}>Confirm SMS</ModalHeader>
          <ModalBody>
            <Row>
              {err ? (
                <Col md="12" style={{ marginTop: 20 }}>
                  <FormGroup>
                    <p style={{ textAlign: "center" }} className="text-danger">
                      {err}
                    </p>
                  </FormGroup>
                </Col>
              ) : null}
              {pass ? (
                <Col md="12" style={{ marginTop: 20 }}>
                  <FormGroup>
                    <p
                      style={{ textAlign: "center", fontWeight: "bold" }}
                      className="text-success"
                    >
                      {pass}
                    </p>
                  </FormGroup>
                </Col>
              ) : null}
              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <lable style={{ margin: "0, 0, 30px,0" }}>Message</lable>
                  <Input
                    style={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                    type="textarea"
                    defaultValue={defaultSMS}
                    onChange={(e) => {
                      setMessage(e.target.value);
                      setError(null);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              onClick={tumaSms}
              disabled={isSubmiting}
              style={{ fontWeight: "bold" }}
            >
              Send Now
            </Button>{" "}
            <Button
              color="secondary"
              onClick={toggle2}
              style={{ fontWeight: "bold" }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default StudentView;
