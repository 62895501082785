import api from "../api";

export const signInAdmin = async (body) => {
  try {
    const admin = await api.post("/admin/login", body);
    return admin;
  } catch (error) {
    console.log(error);
  }
};

export const getAllSchools = async (id) => {
  try {
    const schools = await api.get(`/school/`);

    return schools;
  } catch (error) {
    console.log(error);
  }
};

export const updateSchool = async (id, body) => {
  try {
    const school = await api.put(`/school/update/${id}`, body);
    return school;
  } catch (error) {
    console.log(error);
  }
};

export const addNewSchool = async (body) => {
  try {
    const school = await api.post(`/school/add`, body);
    return school;
  } catch (error) {
    console.log(error);
  }
};

// if (schools.status === 200) {
//     localStorage.setItem("allschools", JSON.stringify(schools.data));
//   }
