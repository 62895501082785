import Dashboard from "views/ADMIN/Admindashboard";
import Profile from "views/ADMIN/Profile";
import Classes from "views/Classes";
import Results from "views/Results";
import Exams from "views/Exams";
import UserPage from "views/UserPage.js";
import Subjects from "views/Subjects";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Schools",
    icon: "design_app",
    component: Dashboard,
    layout: "/main",
  },
  {
    path: "/profile",
    name: "Profile",
    icon: "design_app",
    component: Profile,
    layout: "/main",
  },
  //   {
  //     path: "/classes",
  //     name: "Classes",
  //     icon: "design_bullet-list-67",
  //     component: Classes,
  //     layout: "/admin",
  //   },
  //   {
  //     path: "/subjects",
  //     name: "Subjects",
  //     icon: "media-1_album",
  //     component: Subjects,
  //     layout: "/admin",
  //   },
  //   {
  //     path: "/students",
  //     name: "Students",
  //     icon: "users_single-02",
  //     component: Students,
  //     layout: "/admin",
  //   },

  //   {
  //     path: "/exams",
  //     name: "Exams",
  //     icon: "files_single-copy-04",
  //     component: Exams,
  //     layout: "/admin",
  //   },
  //   {
  //     path: "/results",
  //     name: "Results",
  //     icon: "files_paper",
  //     component: Results,
  //     layout: "/admin",
  //   },
  //   {
  //     path: "/user-page",
  //     name: "Profile",
  //     icon: "design-2_ruler-pencil",
  //     component: UserPage,
  //     layout: "/admin",
  //   },
];
export default dashRoutes;
