import React from "react";
import PanelHeader from "components/PanelHeader/PanelHeader.js";

const Profile = () => {
  return (
    <>
      <PanelHeader size="sm" />
      <button
        onClick={(e) => {
          localStorage.removeItem("school");
          localStorage.removeItem("Allschool");
          localStorage.removeItem("admin");
          return (window.location.href = "/");
        }}
        style={{
          fontWeight: "bold",
          margin: "5%",
        }}
        type="button"
        //onClick={toggle}
        className=" btn btn-danger btn-lg  "
      >
        Log Out
      </button>
    </>
  );
};

export default Profile;
