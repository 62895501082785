import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import PanelHeader from "components/PanelHeader/PanelHeader.js";
import {
  getSchool,
  getSchoolExams,
  getClasses,
  addNewExam,
  changeExam,
  deleteExam,
  getExamType
} from "../controller";
import { thead } from "variables/general";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

function ExamView() {
  const [modal, setModal] = React.useState(false);
  const [modal1, setModal1] = React.useState(false);
  const [exams, setExams] = React.useState([]);
  const [name, setName] = React.useState(null);
  const [doneOn, setDoneOn] = React.useState(null);
  const [classes, setClasses] = React.useState([]);
  const [selectedClass, setSelectedClass] = React.useState(null);
  const [sub, setSub] = React.useState();
  const [subs, setSubs] = React.useState([]);
  const [isSubmiting, setSubmiting] = React.useState(false);
  const [isDelete, setDelete] = React.useState(false);
  const [examtypes, setExamtypes]= React.useState([])
  const [err, setError] = React.useState(null)

  //update
  const [selectID, setSelectID] = React.useState(null);
  const [newname, setnewName] = React.useState(null);
  const [newdoneOn, setnewDoneOn] = React.useState(null);
  const [newselectedClass, setnewSelectedClass] = React.useState(null);
  const [newsub, setnewSub] = React.useState();
  const [newsubs, setnewSubs] = React.useState([]);

  const [search, setSearch] = React.useState(null);



  const toggle = () => {
    setModal(!modal);
  };
  const toggle1 = () => {
    setModal1(!modal1);
  };



  React.useEffect(() => {
    if (!localStorage.getItem("school")) {
      return (window.location.href = "/");
    }
    const profile = JSON.parse(localStorage.getItem("school"));
    getSchoolExams(profile._id)
      .then((re) => {
        setExams(re.data);
        setSearch(re.data);
      })
      .catch((err) => console.log(err));
    getClasses(profile._id)
      .then((re) => setClasses(re.data))
      .catch((err) => console.log(err));
    getSchool(profile._id).then().catch();
    getExamType(profile._id).then(response=>setExamtypes(response.data))
  }, []);

  const twikSubjects = (data) => {
    const list = [];
    data.forEach((sub) => list.push({ value: sub._id, label: sub.name }));
    return list;
  };

  const twickList1 = (data) => {
    const list = [];
    data.forEach((sub) =>
      list.push({
        value: sub._id,
        label: sub.month
          ? sub.name +
            " - " +
            sub.month +
            " / " +
            sub.year +
            ` (${sub.class.name})`
          : sub.name,
      })
    );
    return list;
  };

  const examtypeOptions = twickList1(examtypes);
  const classOptions = twikSubjects(classes);
  const subjectsOptions = twikSubjects(subs)

  const fetchSubs = (id) => {
    classes.forEach((clas) => {
      if (clas._id == id) {
        setSubs(clas.subjects);
        if (clas.subjects.length === 1) {
          setSub(clas.subjects[0]._id);
        }
      }
    });
  };

  const updateSearchByClass = (id) => {
    if (id === "") {
      return setSearch(exams);
    }

    const regexp = new RegExp(id, "i");
    const filtered = exams.filter((x) => regexp.test(x.class._id));
    return setSearch(filtered);
  };

  const updateSearch = (text) => {
    if (text === "") {
      return setSearch(exams);
    }

    const regexp = new RegExp(text, "i");
    const filtered = exams.filter((x) => regexp.test(x.name));
    return setSearch(filtered);
  };

  const toggleLiveStatus = async (cla) => {
    try {
      await changeExam(cla._id, { isLive: !cla.isLive });
      return window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const addExam = async () => {
    let isPass = true
    exams.forEach(resu=>{
      if (resu.name === name && resu.subject._id === sub && resu.class._id === selectedClass ){
       isPass = false;
      }
    })
    try {
      const profile = JSON.parse(localStorage.getItem("school"));
      if (!name || !sub || !selectedClass) {
        return alert("Enter name, subject and class");
      }


     
      if (isPass){
      setSubmiting(true);
      const body = {
        name: name,
        subject: sub,
        class: selectedClass,
        doneOn: doneOn,
        school: profile._id,
      };
      const feed = await addNewExam(body);
      setSubmiting(false);
      if (feed.status === 201) {
        return window.location.reload();
      } else {
        return alert("Failed to add exam, Please try again");
      }
    }else{

        return setError("Exam already added. You can edit existing exam");
      }
    } catch (error) {
      setSubmiting(false);
      console.log(error);
    }
  };

  const updateExam = async () => {
    try {
      if (!newname && !newsub && !newselectedClass && !newdoneOn) {
        return;
      }
      setSubmiting(true);
      const body = {
        name: newname ? newname : selectID.name,
        subject: newsub ? newsub : selectID.subject._id,
        class: newselectedClass ? newselectedClass : selectID.class._id,
        doneOn: newdoneOn ? newdoneOn : selectID.doneOn,
      };
      const feed = await changeExam(selectID._id, body);
      setSubmiting(false);
      if (feed.status === 202) {
        return window.location.reload();
      } else {
        return alert("Failed to update exam, Please try again");
      }
    } catch (error) {
      setSubmiting(false);
      console.log(error);
    }
  };

  const deleteEx = async (id) => {
    try {
      setDelete(true);
      const feed = await deleteExam(id);
      setDelete(false);
      if (feed.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      setDelete(false);
      console.log(error);
    }
  };

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <CardTitle tag="h4">Examination List</CardTitle>
                <Form inline className="ml-auto">
                <FormGroup className="no-border">
                <Select
                    closeMenuOnSelect
                    components={animatedComponents}
                    placeholder="Select Class ..."
                    onChange={(e) => {
                      updateSearchByClass(e.value);    
                    }}
                    options={classOptions}
                  /> 
                   </FormGroup>
                </Form>
                <Form inline className="ml-auto">
                  <FormGroup className="no-border">
                    <Input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => updateSearch(e.target.value)}
                    />
                  </FormGroup>
                </Form>
                <button
                  style={{
                    fontWeight: "bold",
                    marginRight: 10,
                    marginLeft: 40,
                  }}
                  type="button"
                  onClick={toggle}
                  class=" btn btn-info btn-sm "
                >
                  <i style={{ fontSize: 20 }} class="fas fa-plus-circle" />
                </button>
              </CardHeader>
              <CardBody>
                <Table responsive bordered>
                  <thead>
                    <tr>
                      {thead.map((prop, key) => {
                        return (
                          <th
                            key={key}
                            className="text-left"
                            style={{ fontWeight: "bold", fontSize: 14 }}
                          >
                            {prop}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>

                  {search ? (
                    <>
                      {search.map((exam) => (
                        <tbody>
                          <td>{exam.name}</td>
                          <td>{exam.class ? exam.class.name : "no class"}</td>
                          <td>
                            {exam.subject ? exam.subject.name : "no subject"}
                          </td>

                          <td>
                            {exam.isLive ? (
                              <button
                                style={{ fontWeight: "bold" }}
                                type="button"
                                onClick={() => toggleLiveStatus(exam)}
                                className="btn btn-success btn-sm"
                              >
                                Yes
                              </button>
                            ) : (
                              <button
                                style={{ fontWeight: "bold" }}
                                type="button"
                                onClick={() => toggleLiveStatus(exam)}
                                className="btn btn-warning btn-sm"
                              >
                                No
                              </button>
                            )}
                          </td>
                          <td>{exam.doneOn}</td>

                          <td>
                            <button
                              style={{ fontWeight: "bold", marginRight: 10 }}
                              type="button"
                              class="btn btn-info btn-sm "
                              onClick={() => {
                                setSelectID(exam);
                                toggle1();
                              }}
                            >
                              <i class="far fa-edit"></i>
                            </button>
                            <button
                              style={{ fontWeight: "bold" }}
                              type="button"
                              onClick={() => deleteEx(exam._id)}
                              class="btn btn-danger btn-sm "
                            >
                              <i
                                className={
                                  isDelete ? "far fa-flag" : "far fa-trash-alt"
                                }
                              ></i>
                            </button>
                          </td>
                        </tbody>
                      ))}
                    </>
                  ) : null}
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal isOpen={modal} toggle={toggle} backdrop={false}>
          <ModalHeader toggle={toggle}>Add Examination</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12" style={{}}>
                <FormGroup>
                  <label>Examination Name</label>
                  {examtypes.length > 0 ? (
                  <Select
                    closeMenuOnSelect
                    components={animatedComponents}
                    onChange={(e) => setName(e.label)}
                    options={examtypeOptions}
                  />
                ) : (
                  <div style={{ color: "orange" }}>
                    Please you need to add Exam Type before adding Exam.
                  </div>
                )}
                  
                </FormGroup>
              </Col>
              <Col md="12" style={{}}>
                <FormGroup>
                  <label>Examination Date</label>
                  <Input
                    type="text"
                    placeholder="30/09/2021"
                    onChange={(e) => setDoneOn(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <label>Select Class</label>
                  {classes.length > 0 ? (
                    <>
                    <Select
                    closeMenuOnSelect
                    components={animatedComponents}
                    onChange={(e) => {
                      setSelectedClass(e.value);
                          fetchSubs(e.value);
                          setError(null)
                    }}
                    options={classOptions}
                  />    
                    </>
                  ) : (
                    <div style={{ color: "orange" }}>
                      Please you need to add class before adding examination.
                      Add class on Class tab.
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <label>Select Subject</label>
                  {subs.length > 0 ? (
                    <>
                     <Select
                    closeMenuOnSelect
                    components={animatedComponents}
                    onChange={(e) => {setSub(e.value);setError(null)}}
                    options={subjectsOptions}
                  /> 
                     
                    </>
                  ) : (
                    <div style={{color: "blue"}}>Loading...</div>
                  )}
                </FormGroup>
              </Col>

              {err?
              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <p style={{textAlign:'center'}} className="text-danger">{err}</p>
                </FormGroup>
              </Col>:null}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={addExam}
              disabled={isSubmiting}
              style={{ fontWeight: "bold" }}
            >
              Add Exam
            </Button>
            <Button
              color="secondary"
              onClick={toggle}
              style={{ fontWeight: "bold" }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        {selectID ? (
          <Modal isOpen={modal1} toggle={toggle1} backdrop={false}>
            <ModalHeader toggle={toggle1}>Update Examination</ModalHeader>
            <ModalBody>
              <Row>
                <Col md="12" style={{}}>
                  <FormGroup>
                    <label>Examination Name</label>
                    <Select
                    closeMenuOnSelect
                    defaultInputValue={selectID.name}
                    components={animatedComponents}
                    onChange={(e) => setnewName(e.label)}
                    options={examtypeOptions}
                  />
                    
                  </FormGroup>
                </Col>
                <Col md="12" style={{}}>
                  <FormGroup>
                    <label>Examination Date</label>
                    <Input
                      type="text"
                      defaultValue={selectID.doneOn}
                      placeholder="30/09/2021"
                      onChange={(e) => setnewDoneOn(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md="12" style={{ marginTop: 20 }}>
                  <FormGroup>
                    <label>Select Class</label>
                    {classes ? (
                      <>
                      <Select
                    closeMenuOnSelect
                    components={animatedComponents}
                    onChange={(e) => {
                      setnewSelectedClass(e.value);
                          fetchSubs(e.value);
                    }}
                    options={classOptions}
                  />    
                        
                      </>
                    ) : (
                      <div style={{ color: "orange" }}>
                        Please you need to add class before adding examination.
                        Add class on Class tab.
                      </div>
                    )}
                  </FormGroup>
                </Col>

                <Col md="12" style={{ marginTop: 20 }}>
                  <FormGroup>
                    <label>Select Subject</label>
                    {subs.length > 0 ? (
                      <>
                       <Select
                    closeMenuOnSelect
                    components={animatedComponents}
                    onChange={(e) => setnewSub(e.value)}
                    options={subjectsOptions}
                  /> 
                      
                      </>
                    ) : (
                      <div style={{ color: "blue" }}>Loading...</div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                color="info"
                onClick={updateExam}
                disabled={isSubmiting}
                style={{ fontWeight: "bold" }}
              >
                Update Exam
              </Button>
              <Button
                color="secondary"
                onClick={toggle1}
                style={{ fontWeight: "bold" }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        ) : null}
      </div>
    </>
  );
}

export default ExamView;
