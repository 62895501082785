import api from "../api";

export const signInSchool = async (body) => {
  try {
    const school = await api.post("/school/login", body);
    return school;
  } catch (error) {
    console.log(error);
  }
};

export const getSchool = async (id) => {
  try {
    const school = await api.get(`/school/${id}`);
    if (school.status === 200) {
      localStorage.setItem("school", JSON.stringify(school.data));
    }
    return school;
  } catch (error) {
    console.log(error);
  }
};

export const addSubject = async (body) => {
  try {
    const school = await api.post(`/subject/add`, body);
    return school;
  } catch (error) {
    console.log(error);
  }
};

export const getSubjects = async (id) => {
  try {
    const schools = await api.get(`/subject/school/${id}`);
    return schools;
  } catch (error) {
    console.log(error);
  }
};

export const deleteSubject = async (id) => {
  try {
    const school = await api.delete(`/subject/delete/${id}`);
    return school;
  } catch (error) {
    console.log(error);
  }
};

export const updateSubject = async (id, body) => {
  try {
    const school = await api.put(`/subject/update/${id}`, body);
    return school;
  } catch (error) {
    console.log(error);
  }
};

export const addExamType = async (body) => {
  try {
    const examtypes = await api.post(`/examtype/add`, body);
    return examtypes;
  } catch (error) {
    console.log(error);
  }
};

export const getExamType = async (id) => {
  try {
    const examtypes = await api.get(`/examtype/school/${id}`);
    return examtypes;
  } catch (error) {
    console.log(error);
  }
};

export const getTypeFromClass = async (classID) => {
  try {
    const examtype = await api.get(`/examtype/findclass/${classID}`);
    return examtype;
  } catch (error) {
    console.log(error);
  }
};

export const deleteExamType = async (id) => {
  try {
    const examtypes = await api.delete(`/examtype/delete/${id}`);
    return examtypes;
  } catch (error) {
    console.log(error);
  }
};

export const updateExamType = async (id, body) => {
  try {
    const examtypes = await api.put(`/examtype/update/${id}`, body);
    return examtypes;
  } catch (error) {
    console.log(error);
  }
};

export const updateSchoolInfo = async (id, body) => {
  try {
    const school = await api.put(`/school/update/${id}`, body);
    return school;
  } catch (error) {
    console.log(error);
  }
};

export const getOneClass = async (id) => {
  try {
    const classe = await api.get(`/class/${id}`);
    return classe;
  } catch (error) {
    console.log(error);
  }
};

export const getClasses = async (id) => {
  try {
    const classes = await api.get(`/class/school/${id}`);
    return classes;
  } catch (error) {
    console.log(error);
  }
};

export const addNewClass = async (body) => {
  try {
    const classes = await api.post(`/class/add`, body);
    return classes;
  } catch (error) {
    console.log(error);
  }
};

export const updateClass = async (id, body) => {
  try {
    const classes = await api.put(`/class/update/${id}`, body);
    return classes;
  } catch (error) {
    console.log(error);
  }
};

export const deleteClass = async (id) => {
  try {
    const school = await api.delete(`/class/delete/${id}`);
    return school;
  } catch (error) {
    console.log(error);
  }
};

export const deleteSchool = async (id) => {
  try {
    const school = await api.delete(`/school/delete/${id}`);
    return school;
  } catch (error) {
    console.log(error);
  }
};

export const getStudents = async (id) => {
  try {
    const students = await api.get(`/student/school/${id}`);
    return students;
  } catch (error) {
    console.log(error);
  }
};

export const getAllStudents = async () => {
  try {
    const students = await api.get(`/student/`);
    return students;
  } catch (error) {
    console.log(error);
  }
};

export const getOneStudent = async (id) => {
  try {
    const student = await api.get(`/student/${id}`);
    return student;
  } catch (error) {
    console.log(error);
  }
};

export const getClassStudents = async (id) => {
  try {
    const students = await api.get(`/student/classstudents/${id}`);
    return students.data.length;
  } catch (error) {
    console.log(error);
  }
};

export const addNewStudent = async (body) => {
  try {
    const student = await api.post(`/student/add`, body);
    return student;
  } catch (error) {
    console.log(error);
  }
};

export const deleteStudent = async (id) => {
  try {
    const school = await api.delete(`/student/delete/${id}`);
    return school;
  } catch (error) {
    console.log(error);
  }
};

export const updateNewStudent = async (id, body) => {
  try {
    const student = await api.put(`/student/update/${id}`, body);
    return student;
  } catch (error) {
    console.log(error);
  }
};

export const getSchoolExams = async (id) => {
  try {
    const students = await api.get(`/exam/school/${id}`);
    return students;
  } catch (error) {
    console.log(error);
  }
};

export const addNewExam = async (body) => {
  try {
    const exam = await api.post(`/exam/add/`, body);
    return exam;
  } catch (error) {
    console.log(error);
  }
};

export const changeExam = async (id, body) => {
  try {
    const exam = await api.put(`/exam/update/${id}`, body);
    return exam;
  } catch (error) {
    console.log(error);
  }
};

export const deleteExam = async (id) => {
  try {
    const exam = await api.delete(`/exam/delete/${id}`);
    return exam;
  } catch (error) {
    console.log(error);
  }
};

export const getResults = async (id) => {
  try {
    const results = await api.get(`/result/school/${id}`);
    return results;
  } catch (error) {
    console.log(error);
  }
};

export const getResultsOfOneStudent = async (id) => {
  try {
    const results = await api.get(`/result/student/${id}`);
    return results;
  } catch (error) {
    console.log(error);
  }
};

export const addNewResult = async (body) => {
  try {
    const result = await api.post(`/result/add`, body);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const updateNewResult = async (id, body) => {
  try {
    const result = await api.put(`/result/update/${id}`, body);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const deleteResult = async (id) => {
  try {
    const result = await api.delete(`/result/delete/${id}`);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getSomeResults = async (school, name) => {
  try {
    const results = await api.get(`/result/search/${school}/${name}`);
    return results.data;
  } catch (error) {
    console.log(error);
  }
};

export const getClassResults = async (classID, school) => {
  try {
    const results = await api.get(`/result/classResults/${classID}/${school}`);
    return results.data;
  } catch (error) {
    console.log(error);
  }
};

export const sendSMSToMany = async (body) => {
  try {
    const result= await api.post('/message/sendSMS', body)
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const saveMessage = async body =>{
  try {
    const result = await api.post('/message/add', body);
    return result.data;
  } catch (error) {
    console.log(error);
  }
}

export const getAllSMSKind = async (id)=>{
  try {
    const smskinds = await api.get(`/smskind/school/${id}`);
    return smskinds;
  } catch (error) {
    console.log(error);
  }
}

export const getAllSMS = async (id)=>{
  try {
    const smskinds = await api.get(`/message/school/${id}`);
    return smskinds;
  } catch (error) {
    console.log(error);
  }
}

export const addSMSkind = async (body)=>{
  try {
    const smskind = await api.post(`/smskind/add/`,body);
    return smskind;
  } catch (error) {
    console.log(error);
  }
}



