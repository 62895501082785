import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { deleteSchool } from "../../controller";
import {
  getAllSchools,
  addNewSchool,
  updateSchool,
} from "../../controller/main";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

function SchoolsView() {
  const [schools, setSchools] = React.useState(null);
  const [isSubmiting, setSubmiting] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [modal1, setModal1] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  const [regNo, setregNo] = React.useState(null);
  const [name, setName] = React.useState(null);
  const [phone, setphone] = React.useState(null);
  const [email, setemail] = React.useState(null);
  const [level, setlevel] = React.useState(null);
  const [password, setpassword] = React.useState(null);
  const [region, setregion] = React.useState(null);
  const [district, setdistrict] = React.useState(null);
  const [time, setTime] = React.useState(null);
  const [selectedSchool, setSelectedSchool] = React.useState(null);
  const [isDelete, setDelete]= React.useState(false)
  const [deleteID, selectDeleteID]= React.useState(null)
  const [search, setSearch] = React.useState(null);

  const twikSubjects = (data) => {
    const list = [];
    data.forEach((sub) => list.push({ value: sub.id, label: sub.name }));
    return list;
  };

  const toggle = () => {
    setModal(!modal);
  };

  const toggle1 = () => {
    setModal1(!modal1);
  };
  const toggle2 = () => {
    setModal2(!modal2);
  };

  const sele = [
    {
      id: "primary",
      name: "Primary",
    },
    {
      id: "secondary",
      name: "Seconday",
    },
  ];

  const leveOption = twikSubjects(sele);

  React.useEffect(() => {
    if (!localStorage.getItem("admin")) {
      return (window.location.href = "/");
    }

    getAllSchools().then((res) => {
      setSchools(res.data);
      setSearch(res.data);
    });
  }, []);

  const updateSearch = (text) => {
    if (text === "") {
      return setSearch(schools);
    }

    const regexp = new RegExp(text, "i");
    const filtered = schools.filter((x) => regexp.test(x.name));
    return setSearch(filtered);
  };

  const addSchool = async () => {
    try {
      if (
        !name ||
        !level.value ||
        !time ||
        !district ||
        !region ||
        !phone ||
        !regNo ||
        !password ||
        !email
      ) {
        return alert("Fill all fields");
      }
      setSubmiting(true);

      let dat = new Date();
      dat = dat.setDate(dat.getDate() + Number(time));

      const body = {
        name: name,
        regno: regNo,
        region: region,
        district: district,
        phone: Number(phone),
        email: email,
        password: password,
        level: level.value,
        expire: dat,
      };
      const feed = await addNewSchool(body);
      setSubmiting(false);
      if (feed.status === 201) {
        return window.location.reload();
      } else {
        return alert("Failed to add class, Please try again");
      }
    } catch (error) {
      setSubmiting(false);
      console.log(error);
    }
  };

  const updateToNewSchool = async () => {
    try {
      setSubmiting(true);

      setSubmiting(true);

      let dat = new Date();
      dat = dat.setDate(dat.getDate() + Number(time));

      const body = {
        name: name ? name : selectedSchool.name,
        region: region ? region : selectedSchool.region,
        district: district ? district : selectedSchool.district,
        phone: phone ? Number(phone) : selectedSchool.phone,
        email: email ? email : selectedSchool.email,
        level: level ? level.value : selectedSchool.level,
        expire: time ? dat : selectedSchool.expire,
      };
      const feed = await updateSchool(selectedSchool._id, body);
      setSubmiting(false);
      if (feed.status === 202) {
        return window.location.reload();
      } else {
        return alert("Failed to add class, Please try again");
      }
    } catch (error) {
      setSubmiting(false);
      console.log(error);
    }
  };

  const deleteSc = async () => {
    try {
      //console.log(deleteID)
      setDelete(true);
      setSubmiting(true);
      const feed = await deleteSchool(deleteID);
      setDelete(false);
      if (feed.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      setDelete(false);
      console.log(error);
    }
  };

  const head = [
    "School No.",
    "School Name",
    "Level",
    "Region",
    "District",
    "Phone",
    "Expire on",
    "Action",
  ];

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <CardTitle tag="h4">School List</CardTitle>
                <Form inline className="ml-auto">
                  <FormGroup className="no-border">
                    <Input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => updateSearch(e.target.value)}
                    />
                  </FormGroup>
                </Form>
                <button
                  style={{
                    fontWeight: "bold",
                    marginRight: 10,
                    marginLeft: 40,
                  }}
                  type="button"
                  onClick={toggle}
                  className=" btn btn-success btn-sm "
                >
                  <i style={{ fontSize: 20 }} className="fas fa-plus-circle" />
                </button>
              </CardHeader>
              <CardBody>
                <Table responsive bordered>
                  <thead>
                    <tr>
                      {head.map((prop, key) => {
                        return (
                          <th
                            key={key}
                            className="text-left"
                            style={{ fontWeight: "bold", fontSize: 14 }}
                          >
                            {prop}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  {search ? (
                    <>
                      {search.map((shule) => (
                        <tbody>
                          <td>{shule.regno}</td>
                          <td>{shule.name}</td>
                          <td>{shule.level}</td>
                          <td>{shule.region}</td>
                          <td>{shule.district}</td>
                          <td>{shule.phone}</td>
                          <td>
                            {shule.expire
                              ? new Date(shule.expire).toDateString()
                              : "Doesn't expire"}
                          </td>
                          <td>
                            <button
                              style={{ fontWeight: "bold", marginRight: 10 }}
                              type="button"
                              onClick={() => {
                                setSelectedSchool(shule);
                                toggle1();
                              }}
                              className="btn btn-info btn-sm "
                            >
                              <i className="far fa-edit"></i>
                            </button>
                            <button
                              style={{ fontWeight: "bold" }}
                              type="button"
                              onClick={() => {selectDeleteID(shule._id);toggle2()}}
                              className="btn btn-danger btn-sm "
                            >
                              <i
                                className={
                                  isDelete ? "far fa-flag" : "far fa-trash-alt"
                                }
                              ></i>
                            </button>
                          </td>
                        </tbody>
                      ))}
                    </>
                  ) : null}
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal isOpen={modal} toggle={toggle} backdrop={false}>
          <ModalHeader toggle={toggle}>Add New School</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <label>School Registration No.</label>
                  <Input
                    type="text"
                    onChange={(e) => setregNo(e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <label>School Name</label>
                  <Input
                    type="text"
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <label>Password(more than 6 chars)</label>
                  <Input
                    type="text"
                    onChange={(e) => setpassword(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <label>Level</label>
                  <Select
                    closeMenuOnSelect
                    components={animatedComponents}
                    onChange={(e) => setlevel(e)}
                    options={leveOption}
                  />
                </FormGroup>
              </Col>

              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <label>School Phone</label>
                  <Input
                    type="phone-pad"
                    onChange={(e) => setphone(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <label>School Email</label>
                  <Input
                    type="email"
                    onChange={(e) => setemail(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <label>School Region</label>
                  <Input
                    type="text"
                    onChange={(e) => setregion(e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <label>School District</label>
                  <Input
                    type="text"
                    onChange={(e) => setdistrict(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <label>Days(Time to use a service)</label>
                  <Input
                    type="number"
                    onChange={(e) => setTime(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              onClick={addSchool}
              disabled={isSubmiting}
              style={{ fontWeight: "bold" }}
            >
              Add School
            </Button>{" "}
            <Button
              color="secondary"
              onClick={toggle}
              style={{ fontWeight: "bold" }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modal1} toggle={toggle1} backdrop={false}>
          <ModalHeader toggle={toggle1}>Update School</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <label>School Name</label>
                  <Input
                    type="text"
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <label>Level</label>
                  <Select
                    closeMenuOnSelect
                    components={animatedComponents}
                    onChange={(e) => setlevel(e)}
                    options={leveOption}
                  />
                </FormGroup>
              </Col>

              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <label>School Phone</label>
                  <Input
                    type="phone-pad"
                    onChange={(e) => setphone(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <label>School Email</label>
                  <Input
                    type="email"
                    onChange={(e) => setemail(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <label>School Region</label>
                  <Input
                    type="text"
                    onChange={(e) => setregion(e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <label>School District</label>
                  <Input
                    type="text"
                    onChange={(e) => setdistrict(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <label>Days(Time to use a service)</label>
                  <Input
                    type="number"
                    onChange={(e) => setTime(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="info"
              onClick={updateToNewSchool}
              disabled={isSubmiting}
              style={{ fontWeight: "bold" }}
            >
              Update School
            </Button>{" "}
            <Button
              color="secondary"
              onClick={toggle1}
              style={{ fontWeight: "bold" }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>


        <Modal isOpen={modal2} toggle={toggle2} backdrop={false}>
          <ModalHeader toggle={toggle2}>Confirm Delete</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12" style={{marginTop: 20 }}>
                <FormGroup>
                  <label>You are about to delete school. Are you sure?</label>
                </FormGroup>
              </Col>

      

            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={deleteSc}
              disabled={isSubmiting}
              style={{ fontWeight: "bold" }}
            >
              Confirm Delete
            </Button>{" "}
            <Button
              color="secondary"
              onClick={toggle2}
              style={{ fontWeight: "bold" }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default SchoolsView;
