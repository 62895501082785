import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import DataTable from "react-data-table-component";
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import {
  getSchool,
  getClasses,
  getSomeResults,
  sendSMSToMany,
} from "../controller";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

function ClassView(props) {
  const [school, setSchool] = React.useState(null);
  const [madarasa, setMadarasa] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [selectedClass, setSelectedClass] = React.useState(null);
  const [someResults, setSomeResults] = React.useState([]);
  const [search, setSearch] = React.useState(null);
  const [isSubmiting, setSubmiting] = React.useState(false);
  const [modal, setModal] = React.useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const twikList = (data) => {
    const list = [];
    data.forEach((sub) =>
      list.push({ value: sub._id, label: sub.name, full: sub })
    );
    return list;
  };

  const classOptions = twikList(madarasa);

  const determineHide = (subjectID) => {
    let isPass = false;
    search.forEach((result) => {
      result.exams.forEach((ex) => {
        if (ex.exam.subject._id === subjectID) {
          return (isPass = true);
        }
      });
    });
    return isPass;
  };

  React.useEffect(() => {
    const { state } = props.location;
    if (!localStorage.getItem("school")) {
      return (window.location.href = "/");
    }
    const profile = JSON.parse(localStorage.getItem("school"));
    setSchool(profile);

    if (selectedClass) {
      let newcolumns = [];
      selectedClass.subjects.forEach((subject) => {
        if (determineHide(subject._id)) {
          newcolumns.push({
            name: subject.name,
            selector: subject.name,
            sortable: true,
          });
        }
      });
      newcolumns.unshift({
        name: "Student",
        selector: "student",
        grow: 2,
      });
      newcolumns.push({
        name: "Total",
        selector: "total",
        sortable: true,
        right: true,
      });

      newcolumns.push({
        name: "Average",
        selector: "average",
        sortable: true,
        right: true,
      });
      newcolumns.push({
        name: "Grade",
        selector: "grade",
        sortable: true,
        right: true,
      });
      newcolumns.push({
        name: "Rank",
        selector: "rank",
        sortable: true,
        right: true,
      });
      setColumns(newcolumns);
    }

    if (search) {
      const sortObject = (array) => {
        let newObj = {};
        array.forEach((arr, index) => {
          newObj = {
            ...newObj,
            [arr.exam.subject.name]: arr.marks,
            total: newObj.total ? newObj.total + arr.marks : arr.marks,
            average: newObj.total
              ? ((newObj.total + arr.marks) / (index + 1)).toFixed(2)
              : (arr.marks / (index + 1)).toFixed(2),
          };
        });

        return newObj;
      };

      const makeRank = (sum) => {
        let myRank = 1;
        search.forEach((list) => {
          let total = 0;
          list.exams.forEach((ele) => {
            total = total + ele.marks;
          });
          if (sum < total) {
            myRank = myRank + 1;
          }
        });
        return myRank;
      };

      let newData = [];
      search.forEach((result) => {
        let sorted = sortObject(result.exams);

        newData.push({
          ...sorted,
          student: result?.student?.name,
          parentPhone: result?.student?.parentPhone,
          rank: makeRank(sorted.total),
          grade: calculateGrade(sorted.average),
        });
      });

      setData(newData);
    }

    getSomeResults(profile._id, `${state.name}`).then((response) => {
      setSomeResults(response);
      console.log(response);
      setSearch(response);
    });

    getClasses(profile._id)
      .then((re) => {
        setMadarasa(re.data);
      })
      .catch((err) => console.log(err));
    getSchool(profile._id).then().catch();
  }, [selectedClass]);




  const updateSearchByClass = (id) => {
    if (id === "") {
      return setSearch(someResults);
    }

    const regexp = new RegExp(id, "i");
    const filtered = someResults.filter((x) => regexp.test(x.class._id));
    return setSearch(filtered);
  };

  const calculateGrade = (mark) => {
    if (school && school.level === "secondary") {
      switch (true) {
        case mark >= 75:
          return "A";
        case mark >= 65 && mark < 75:
          return "B";
        case mark >= 45 && mark < 65:
          return "C";
        case mark >= 30 && mark < 45:
          return "D";
        case mark >= 0 && mark < 29:
          return "F";
        default:
          break;
      }
    } else if (school && school.level === "primary") {
      switch (true) {
        case mark >= 81:
          return "A";
        case mark >= 61 && mark < 81:
          return "B";
        case mark >= 41 && mark < 61:
          return "C";
        case mark >= 21 && mark < 41:
          return "D";
        case mark >= 0 && mark < 21:
          return "F";
        default:
          break;
      }
    }
  };

  const pataMessage = async (stude) => {
    let newStudent = { ...stude };
    let shule = await localStorage.getItem("school");
    shule = JSON.parse(shule);
    delete newStudent.average;
    delete newStudent.grade;
    delete newStudent.parentPhone;
    delete newStudent.rank;
    delete newStudent.student;
    delete newStudent.total;

  Object.keys(newStudent).forEach(function(key){ newStudent[key] = `${newStudent[key]}%` });
   
    const text = `Habari ndugu Mzazi/Mlezi.
      \n\n${shule.name} inapenda kukuarifu kuwa matokeo ya ${
      props.location.state.name
    }, ${selectedClass.name} sasa yametoka.
      \n\nMatokeo ya ${stude.student} ni kama yafuatayo\nJumla ya alama: ${
      stude.total
    },\nWastani: ${stude.average}%,\nNafasi: ${stude.rank}\n\n${JSON.stringify(
      newStudent,
      null,
      2
    )}\n\nUtaweza kujua maendeleo ya mwanao kupitia app ya Bara. Kama huna unashauriwa kupakua sasa.\n\nhttps://onelink.to/baraapp 
      `;

    return text;
  };

  const onSendSms = async () => {
    try {
      setModal(false);
      let numbers = [];

      data.forEach((mwanafuzi) => {
        let number;
        let sms = "";
        if (mwanafuzi.parentPhone) {
          if (mwanafuzi.parentPhone.toString()[0] !== "2") {
            number = `255${mwanafuzi.parentPhone}`;
          } else {
            number = `${mwanafuzi.parentPhone}`;
          }
          pataMessage(mwanafuzi)
            .then((back) => {
              
              const body = {
                to: number,
                text: back,
              };
              sendSMSToMany(body).then((feedback) => {
                return alert("SMS sent successfully");
              });
              
            })
            .catch((err) => console.log(err));
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const openModal = () => {
    if (!selectedClass || data.length < 1) {
      return alert("There is no recipient, Make sure results are shown below");
    } else {
      return setModal(!modal);
    }
  };

  const tableData = {
    columns: columns,
    data: data,
  };

  return (
    <div className="wrapper">
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <CardTitle tag="h4">{props.location.state.name}</CardTitle>
                <Form inline className="ml-auto">
                  <FormGroup className="no-border">
                    <Select
                      closeMenuOnSelect
                      components={animatedComponents}
                      placeholder="Select Class ..."
                      onChange={(e) => {
                        setSelectedClass(e.full);
                        updateSearchByClass(e.value);
                      }}
                      options={classOptions}
                    />
                  </FormGroup>
                </Form>
              </CardHeader>
              <CardBody>
                <DataTableExtensions
                  {...tableData}
                  exportHeaders
                  sendSms={openModal}
                >
                  <DataTable noHeader defaultSortAsc={false} highlightOnHover />
                </DataTableExtensions>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      <Modal isOpen={modal} toggle={toggle} backdrop={false}>
        <ModalHeader toggle={toggle}>Confirm SMS</ModalHeader>
        <ModalBody>
          <p>
            You are about to send a notification SMS to all student's parents
            listed below.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={onSendSms}
            disabled={isSubmiting}
            style={{ fontWeight: "bold" }}
          >
            Send SMS
          </Button>
          <Button
            color="secondary"
            onClick={toggle}
            style={{ fontWeight: "bold" }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ClassView;
