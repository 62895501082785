import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import PanelHeader from "components/PanelHeader/PanelHeader.js";
import {
  getSchool,
  getResults,
  getSchoolExams,
  getStudents,
  getClasses,
  addNewResult,
  deleteResult,
  getAllStudents,
  updateNewResult,
  getExamType,
} from "../controller";

import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

function ResultView() {
  const [school, setSchool] = React.useState(null);
  const [name, setName] = React.useState(null);
  const [modal, setModal] = React.useState(false);
  const [modal1, setModal1] = React.useState(false);
  const [marks, setMarks] = React.useState(null);
  const [rank, setRank] = React.useState(null);
  const [results, setResults] = React.useState([]);
  const [students, setStudents] = React.useState([]);
  const [stude, setStude] = React.useState([]);
  const [newStudents, setnewStudents] = React.useState([]);
  const [stud, setStud] = React.useState(null);
  const [exams, setExams] = React.useState([]);
  const [exs, setExs] = React.useState([]);
  const [oneExam, setOneExam] = React.useState(null);
  const [class1, setClass] = React.useState(null);
  const [isSubmiting, setSubmiting] = React.useState(false);
  const [madarasa, setMadarasa] = React.useState([]);
  const [isDelete, setDelete] = React.useState(false);
  const [examtypes, setExamtypes] = React.useState([]);
  const [selectedResult, setSelectedResult] = React.useState(null);
  const [err, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const [compoundExamName, setCompontExamName] = React.useState([]);

  const [search, setSearch] = React.useState([]);

  const twickList = (data, more) => {
    const list = [];
    data.forEach((sub) =>
      list.push({
        value: sub._id,
        label: more === 0 ? sub.name : `${sub.name}(${sub.subject.name})`,
      })
    );
    return list;
  };

  const twickList1 = (data) => {
    const list = [];
    data.forEach((sub) =>
      list.push({
        value: sub._id,
        label: sub.month
          ? sub.name +
            " - " +
            sub.month +
            " / " +
            sub.year +
            ` (${sub.class.name})`
          : sub.name,
      })
    );
    return list;
  };

  React.useEffect(() => {
    if (!localStorage.getItem("school")) {
      return (window.location.href = "/");
    }
    const profile = JSON.parse(localStorage.getItem("school"));
    setSchool(profile);
    getResults(profile._id)
      .then((re) => {
        setResults(re.data);
        setSearch(re.data);
      })
      .catch((err) => console.log(err));
    getClasses(profile._id)
      .then((re) => setMadarasa(re.data))
      .catch((err) => console.log(err));
    getSchool(profile._id).then().catch();
    getSchoolExams(profile._id)
      .then((re) => setExams(re.data))
      .catch((err) => console.log(err));
    getStudents(profile._id)
      .then((re) => setStudents(re.data))
      .catch((err) => console.log(err));
    getAllStudents().then((res) => setStude(res.data));
    getExamType(profile._id).then((response) => setExamtypes(response.data));
  }, []);

  const toggle = () => {
    setModal(!modal);
  };
  const toggle1 = () => {
    setModal1(!modal1);
  };

  const classOption = twickList(madarasa, 0);
  const examtypeOptions = twickList1(examtypes);

  const fetchExam = (id) => {
    let newExams = [];

    if (name) {
      exams.forEach((exm) => {
        if (exm.class._id == id && exm.name === name) {
          newExams.push(exm);
        }
      });
    } else {
      exams.forEach((exm) => {
        if (exm.class._id == id) {
          newExams.push(exm);
        }
      });
    }
    setExs(twickList(newExams, 1));
  };

  const fetchStudent = (id) => {
    let newStuds = [];
    students.forEach((st) => {
      if (st.class._id === id) {
        newStuds.push(st);
      }
    });
    setnewStudents(twickList(newStuds, 0));
  };

  const rankStudents = (tokeo, tot, name) => {
    let newList = [];
    let myRank = 1;
    let myTotal = tot;
    results.forEach((result) => {
      if (tokeo.class._id === result.class._id && tokeo.name === result.name) {
        newList.push(result);
      }
    });
    newList.forEach((list) => {
      let total = 0;
      list.exams.forEach((ele) => {
        total = total + ele.marks;
      });
      if (myTotal < total) {
        myRank = myRank + 1;
      }
    });
    return myRank;
  };

  const updateSearch = (text) => {
    if (text === "") {
      return setSearch(results);
    }

    const regexp = new RegExp(text, "i");
    const filtered = results.filter((x) => regexp.test(x.student.name));
    return setSearch(filtered);
  };

  const updateSearchByClass = (id) => {
    if (id === "") {
      return setSearch(results);
    }

    const regexp = new RegExp(id, "i");
    const filtered = results.filter((x) => regexp.test(x.class._id));
    return setSearch(filtered);
  };

  const refreshBrowser = () => {
    return window.location.reload();
  };

  const addResult = async () => {
    const profile = JSON.parse(localStorage.getItem("school"));

    if (!name || !stud || !class1 || !compoundExamName.length > 0) {
      return alert("Fill all fields");
    }
    let isPass = true;
    results.forEach((resu) => {
      if (
        resu.name === name &&
        resu.student._id === stud &&
        resu.class._id === class1
      ) {
        isPass = false;
      }
    });

    try {
      if (isPass) {
        const body = {
          name: name,
          student: stud,
          class: class1,
          school: profile._id,
          rank: rank,
          exams: compoundExamName,
        };

        setSubmiting(true);
        const feed = await addNewResult(body);
        setSubmiting(false);
        if (feed.status === 201) {
          setStud(null);
          setCompontExamName([]);
          return setSuccess("Result added successfuly");
        } else {
          return alert("Failed to add exam, Please try again");
        }
      } else {
        return alert(
          "Results already added. You can edit existing student's result"
        );
      }
    } catch (error) {
      setSubmiting(false);
      console.log(error);
    }
  };

  const updateResult = async () => {
    try {
      const body = {
        name: name ? name : selectedResult.name,
        student: stud ? stud : selectedResult.student._id,
        class: selectedResult.class._id,
        school: selectedResult.school,
        rank: rank ? rank : selectedResult.rank,
        exams: compoundExamName,
      };

      setSubmiting(true);
      const feed = await updateNewResult(selectedResult._id, body);
      setSubmiting(false);
      if (feed.status === 202) {
        return window.location.reload();
      } else {
        return alert("Failed to add exam, Please try again");
      }
    } catch (error) {
      setSubmiting(false);
      console.log(error);
    }
  };

  const deleteRe = async (id) => {
    try {
      setDelete(true);
      const feed = await deleteResult(id);
      setDelete(false);
      if (feed.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      setDelete(false);
      console.log(error);
    }
  };

  const calculateGrade = (mark) => {
    if ((school&&school.level === "secondary")) {
      switch (true) {
        case mark >= 75:
          return "A";
        case mark >= 65 && mark < 75:
          return "B";
        case mark >= 45 && mark < 65:
          return "C";
        case mark >= 30 && mark < 45:
          return "D";
        case mark >= 0 && mark < 29:
          return "F";
        default:
          break;
      }
    }else if ((school&&school.level === "primary")) {
      switch (true) {
        case mark >= 81:
          return "A";
        case mark >= 61 && mark < 81:
          return "B";
        case mark >= 41 && mark < 61:
          return "C";
        case mark >= 21 && mark < 41:
          return "D";
        case mark >= 0 && mark < 21:
          return "F";
        default:
          break;
      }
    }
  };

  const calculateRemarks = (mark) => {
    switch (true) {
      case mark >= 75:
        return "Excellent";
      case mark >= 65 && mark < 75:
        return "Very good";
      case mark >= 45 && mark < 65:
        return "Average";
      case mark >= 30 && mark < 45:
        return "Satisfaction";
      case mark >= 0 && mark < 29:
        return "Fail";
      default:
        break;
    }
  };

  const addExamResult = () => {
    if (!oneExam || !marks) {
      return alert("Fill marks, grade and exam");
    }

    if (marks > 100) {
      return setError("Marks should be less than 100");
    }

    let hasPass = true;
    compoundExamName.forEach((comp) => {
      if (comp.exam === oneExam.value) {
        hasPass = false;
      }
    });

    if (hasPass) {
      const newComp = {
        val: oneExam.label,
        exam: oneExam.value,
        marks: marks,
        grade: calculateGrade(Number(marks)),
        remarks: calculateRemarks(Number(marks)),
      };

      setCompontExamName((res) => [...res, newComp]);
    } else {
      return setError("Exam already added to list.");
    }
  };

  const removeExamResult = (suc) => {
    const list = compoundExamName.filter((ele) => {
      return ele.exam != suc;
    });
    setCompontExamName(list);
  };

  const noClassStudents = (id) => {
    let number = 0;
    stude.forEach((stu) => {
      if (stu.class === id) {
        number = number + 1;
      }
    });
    return number;
  };

  const calculateTotalMarks = (array) => {
    let total = 0;
    array.forEach((element) => {
      total = total + element.marks;
    });
    return total;
  };

  const renderExams = (resu) => {
    let obj = [];
    resu.exams.forEach((res) =>
      obj.push({
        val: res.exam ? res.exam.subject.name : " ",
        exam: res.exam ? res.exam._id : " ",
        marks: res.marks,
        grade: res.grade,
        remarks: res.remarks,
      })
    );
    return obj;
  };

  const head = [
    "Exam",
    "Class",
    "Student",
    "Subject & Marks",
    "Rank",
    "Action",
  ];

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <CardTitle tag="h4">Result List</CardTitle>
                <Form inline className="ml-auto">
                  <FormGroup className="no-border">
                    <Select
                      closeMenuOnSelect
                      components={animatedComponents}
                      placeholder="Select Class ..."
                      onChange={(e) => {
                        updateSearchByClass(e.value);
                      }}
                      options={classOption}
                    />
                  </FormGroup>
                </Form>
                <Form inline className="ml-auto">
                  <FormGroup className="no-border">
                    <Input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => updateSearch(e.target.value)}
                    />
                  </FormGroup>
                </Form>
                <button
                  style={{
                    fontWeight: "bold",
                    marginRight: 10,
                    marginLeft: 40,
                  }}
                  type="button"
                  onClick={toggle}
                  class=" btn btn-info btn-sm "
                >
                  <i style={{ fontSize: 20 }} class="fas fa-plus-circle" />
                </button>
              </CardHeader>
              <CardBody>
                <Table responsive bordered>
                  <thead>
                    <tr>
                      {head.map((prop, key) => {
                        return (
                          <th
                            key={key}
                            className="text-left"
                            style={{ fontWeight: "bold", fontSize: 14 }}
                          >
                            {prop}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  {search.length > 0 ? (
                    <>
                      {search.map((result) => (
                        <tbody>
                          <td>{result.name}</td>
                          <td>{result.class.name}</td>

                          <td>
                            {result.student ? result.student.name : "No name"}
                          </td>
                          <td>
                            <ul class="list-group">
                              {result.exams.map((exa) => (
                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                  {exa.exam && exa.exam.subject
                                    ? exa.exam.subject.name
                                    : "no subject"}
                                  <span
                                    style={{ fontWeight: "bold" }}
                                    class="text-success"
                                  >
                                    {exa.marks}%{" "}
                                    <span className="text-info">
                                      {" "}
                                      {exa.grade}
                                    </span>
                                  </span>
                                </li>
                              ))}
                            </ul>
                            <p style={{ fontWeight: "bold", marginTop: 5 }}>
                              Total Marks:
                              <span
                                style={{ marginLeft: 5 }}
                                className="text-info"
                              >
                                {calculateTotalMarks(result.exams)}
                              </span>
                            </p>
                          </td>
                          <td>
                            {rankStudents(
                              result,
                              calculateTotalMarks(result.exams)
                            )}
                            /{noClassStudents(result.class._id)}
                          </td>

                          <td>
                            <button
                              style={{ fontWeight: "bold", marginRight: 10 }}
                              type="button"
                              onClick={() => {
                                setSelectedResult(result);
                                setCompontExamName(renderExams(result));
                                fetchExam(result.class._id);
                                toggle1();
                              }}
                              className="btn btn-warning btn-sm"
                            >
                              Edit
                            </button>
                            <button
                              style={{ fontWeight: "bold" }}
                              type="button"
                              onClick={() => deleteRe(result._id)}
                              class="btn btn-danger btn-sm "
                            >
                              <i
                                className={
                                  isDelete ? "far fa-flag" : "far fa-trash-alt"
                                }
                              ></i>
                            </button>
                          </td>
                        </tbody>
                      ))}
                    </>
                  ) : null}
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal isOpen={modal} toggle={toggle} backdrop={false}>
          <ModalHeader toggle={toggle}>Add Result</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12" style={{}}>
                <FormGroup>
                  <label>Result Name</label>
                  <Select
                    closeMenuOnSelect
                    components={animatedComponents}
                    onChange={(e) => {
                      setName(e.label);
                      setSuccess(null);
                    }}
                    options={examtypeOptions}
                  />
                </FormGroup>
              </Col>

              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <label>Select Class</label>
                  {madarasa.length > 0 ? (
                    <Select
                      closeMenuOnSelect
                      components={animatedComponents}
                      onChange={(e) => {
                        setClass(e.value);
                        setOneExam(null);
                        setStud(null);
                        setSuccess(null);
                        setCompontExamName([]);
                        fetchStudent(e.value);
                        fetchExam(e.value);
                      }}
                      options={classOption}
                    />
                  ) : (
                    <div style={{ color: "blue" }}>Loading...</div>
                  )}
                </FormGroup>
              </Col>

              <Col md="12" style={{ marginTop: 20 }}>
                <FormGroup>
                  <label>Select Student</label>
                  {newStudents.length > 0 ? (
                    <Select
                      closeMenuOnSelect
                      components={animatedComponents}
                      onChange={(e) => {
                        setCompontExamName([]);
                        setStud(e.value);
                        setSuccess(null);
                      }}
                      options={newStudents}
                    />
                  ) : (
                    <div style={{ color: "blue" }}>Loading...</div>
                  )}
                </FormGroup>
              </Col>

              <Col md="6" style={{ marginTop: 20 }}>
                <FormGroup>
                  <label>Select Exam and Fill Result</label>
                  {exs ? (
                    <Select
                      closeMenuOnSelect
                      components={animatedComponents}
                      onChange={(e) => {
                        setOneExam(e);
                        setError(null);
                        setSuccess(null);
                      }}
                      options={exs}
                    />
                  ) : (
                    <div style={{ color: "orange" }}>
                      Please you need to add examination before adding result.
                      Add examination on Exam tab.
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col md="3" style={{ marginTop: 20 }}>
                <FormGroup>
                  <label>Marks %</label>
                  <Input
                    type="text"
                    onChange={(e) => {
                      setMarks(e.target.value);
                      setError(null);
                    }}
                  />
                </FormGroup>
              </Col>

              <Col md="3" style={{ marginTop: 35 }}>
                <FormGroup>
                  <Button
                    color="success"
                    onClick={addExamResult}
                    disabled={isSubmiting}
                    style={{ fontWeight: "bold" }}
                  >
                    Add
                  </Button>
                </FormGroup>
              </Col>

              {err ? (
                <Col md="12" style={{ marginTop: 20 }}>
                  <FormGroup>
                    <p style={{ textAlign: "center" }} className="text-danger">
                      {err}
                    </p>
                  </FormGroup>
                </Col>
              ) : null}
              {success ? (
                <Col md="12" style={{ marginTop: 20 }}>
                  <FormGroup>
                    <p style={{ textAlign: "center" }} className="text-success">
                      {success}
                    </p>
                  </FormGroup>
                </Col>
              ) : null}

              <Col md="12" style={{ marginTop: 20 }}>
                {compoundExamName.length > 0 ? (
                  <FormGroup>
                    {compoundExamName.map((comp) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",

                          border: "1px solid #eee",
                          padding: "0 10px",
                          borderRadius: 3,
                          marginBottom: 5,
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <p style={{ marginRight: 10 }}>{comp.val}</p>
                          <p style={{ color: "green" }}>{comp.marks}%</p>
                          <p style={{ marginLeft: 10 }}>{comp.grade}</p>
                          <p style={{ marginLeft: 10 }}>{comp.remarks}</p>
                        </div>
                        <div
                          onClick={() => removeExamResult(comp.exam)}
                          style={{
                            color: "red",
                            cursor: "pointer",
                            fontSize: 18,
                          }}
                        >
                          x
                        </div>
                      </div>
                    ))}
                  </FormGroup>
                ) : null}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={addResult}
              disabled={isSubmiting}
              style={{ fontWeight: "bold" }}
            >
              Add Result
            </Button>
            <Button
              color="secondary"
              onClick={refreshBrowser}
              style={{ fontWeight: "bold" }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        {selectedResult ? (
          <Modal isOpen={modal1} toggle={toggle1} backdrop={false}>
            <ModalHeader toggle={toggle1}>Update Result</ModalHeader>
            <ModalBody>
              <Row>
                <Col md="12" style={{}}>
                  <FormGroup>
                    <label>Result Name</label>
                    <Select
                      closeMenuOnSelect
                      components={animatedComponents}
                      defaultInputValue={selectedResult.name}
                      onChange={(e) => {
                        setName(e.label);
                      }}
                      options={examtypeOptions}
                    />
                  </FormGroup>
                </Col>

                <Col md="12" style={{ marginTop: 20 }}>
                  <FormGroup>
                    <label>Student</label>
                    {newStudents.length > 0 ? (
                      <Select
                        closeMenuOnSelect
                        defaultInputValue={selectedResult.student?.name}
                        components={animatedComponents}
                        onChange={(e) => {
                          setStud(e.value);
                        }}
                        options={newStudents}
                      />
                    ) : (
                      <div style={{ color: "blue" }}>Loading...</div>
                    )}
                  </FormGroup>
                </Col>

                <Col md="6" style={{ marginTop: 20 }}>
                  <FormGroup>
                    <label>Select Exam and Fill Result</label>
                    {exs ? (
                      <Select
                        closeMenuOnSelect
                        components={animatedComponents}
                        onChange={(e) => {
                          setOneExam(e);
                        }}
                        options={exs}
                      />
                    ) : (
                      <div style={{ color: "orange" }}>
                        Please you need to add examination before adding result.
                        Add examination on Exam tab.
                      </div>
                    )}
                  </FormGroup>
                </Col>

                <Col md="3" style={{ marginTop: 20 }}>
                  <FormGroup>
                    <label>Marks %</label>
                    <Input
                      type="text"
                      onChange={(e) => setMarks(e.target.value)}
                    />
                  </FormGroup>
                </Col>

                <Col md="3" style={{ marginTop: 35 }}>
                  <FormGroup>
                    <Button
                      color="success"
                      onClick={addExamResult}
                      disabled={isSubmiting}
                      style={{ fontWeight: "bold" }}
                    >
                      Add
                    </Button>
                  </FormGroup>
                </Col>

                {err ? (
                  <Col md="12" style={{ marginTop: 20 }}>
                    <FormGroup>
                      <p
                        style={{ textAlign: "center" }}
                        className="text-danger"
                      >
                        {err}
                      </p>
                    </FormGroup>
                  </Col>
                ) : null}

                <Col md="12" style={{ marginTop: 20 }}>
                  {compoundExamName.length > 0 ? (
                    <FormGroup>
                      {compoundExamName.map((comp) => (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",

                            border: "1px solid #eee",
                            padding: "0 10px",
                            borderRadius: 3,
                            marginBottom: 5,
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <p style={{ marginRight: 10 }}>{comp.val}</p>
                            <p style={{ color: "green" }}>{comp.marks}%</p>
                            <p style={{ marginLeft: 10 }}>{comp.grade}</p>
                          </div>
                          <div
                            onClick={() => removeExamResult(comp.exam)}
                            style={{
                              color: "red",
                              cursor: "pointer",
                              fontSize: 18,
                            }}
                          >
                            x
                          </div>
                        </div>
                      ))}
                    </FormGroup>
                  ) : null}
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                color="success"
                onClick={updateResult}
                disabled={isSubmiting}
                style={{ fontWeight: "bold" }}
              >
                Update Result
              </Button>
              <Button
                color="secondary"
                onClick={toggle1}
                style={{ fontWeight: "bold" }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        ) : null}
      </div>
    </>
  );
}

export default ResultView;
